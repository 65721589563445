import React, { useState, useMemo, useEffect } from 'react';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import image1 from './images/ball.png';
import image2 from './images/balti.png';
import image3 from './images/diya.png';
import image4 from './images/cow.png';
import image5 from './images/kabutar.png';
import image6 from './images/kite.png';
import image7 from './images/lattu.png';
import image8 from './images/rabbit.png';
import image9 from './images/rose.png';
import image10 from './images/sun.jpg';
import image11 from './images/titli.png';
import image12 from './images/umbrella.png';
import NewBottomBar from './newBottomBar';

const GameHist = ({ response, currentPage, handlePage, showNav = true, tableHeading = "Game History", showBottom = true }) => {
    const [gameHistoryData, setGameHistoryData] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [error, setError] = useState(null);
    const rowsPerPage = 7;
    
 
// // console.log(response);

    const items = useMemo(() => [
        { id: 1, label: 'छतरी', image: image12 },
        { id: 2, label: 'बॉल', image: image1 },
        { id: 3, label: 'सूरज', image: image10 },
        { id: 4, label: 'दिया', image: image3 },
        { id: 5, label: 'गाय', image: image4 },
        { id: 6, label: 'बाल्टी', image: image2 },
        { id: 7, label: 'पतंग', image: image6 },
        { id: 8, label: 'लट्टू', image: image7 },
        { id: 9, label: 'गुलाब', image: image9 },
        { id: 10, label: 'तितली', image: image11 },
        { id: 11, label: 'कबूतर', image: image5 },
        { id: 12, label: 'खरगोश', image: image8 }
    ], []);
    useEffect(() => {
        if (response && response.data.items) {
            const totalCount = response.data.total_count || 0;
            const pages = Math.ceil(totalCount / rowsPerPage);
          
            const gameHistoryDataToSet = Array.isArray(response.data.items) 
                ? response.data.items.map(item => ({
                    id: item.id,
                    result: item.result,
                    created_at: new Date(item.created_at * 1000).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
                }))
                : [];
            // // console.log(gameHistoryDataToSet);
            setGameHistoryData(gameHistoryDataToSet);
            setTotalPages(10);
        } else {
            setError("Invalid response data");
        }
    }, [response]);
       
   

    const toggleRow = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            handlePage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            handlePage(currentPage + 1);
        }
    };

    return (
        <div className="bg-black text-white p-4">
            <h1 className="text-xl font-bold px-2 rounded-t-lg bg-[#1a1a1a]">{showBottom && tableHeading}</h1>
            <div className="bg-[#1a1a1a] rounded-b-lg p-4 mb-4">
                {error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    <table className="w-full table-auto">
                        <thead className="text-yellow-500 border-b border-gray-500">
                            <tr>
                                <th className="py-2 text-left">Session ID</th>
                                <th className="py-2 text-center">Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gameHistoryData.map((game, index) => {
                                const resultItem = items.find(item => item.id === game.result);

                                return (
                                    <React.Fragment key={index}>
                                        <tr
                                            className="border-b border-gray-700 cursor-pointer"
                                            onClick={() => toggleRow(index)}
                                        >
                                            <td className="py-2 text-left">{game.id}</td>
                                            <td className="py-2 text-center flex justify-between items-center">
                                                {resultItem && (
                                                    <img src={resultItem.image} alt={resultItem.label} className="rounded-full w-8 h-8" />
                                                )}
                                                {resultItem ? resultItem.label : 'N/A'}
                                            </td>
                                        </tr>

                                        {expandedRow === index && (
                                            <tr className="bg-gray-800">
                                                <td colSpan="2" className="p-4">
                                                    <div className="flex flex-col items-start space-y-2">
                                                        <div className="flex items-center space-x-2">
                                                            <p><strong>Result:</strong> {resultItem ? resultItem.label : 'N/A'}</p>
                                                            {resultItem && (
                                                                <img src={resultItem.image} alt={resultItem.label} className="w-12 h-12" />
                                                            )}
                                                        </div>
                                                        <p><strong>Timestamp:</strong> {game.created_at}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            <div className="flex justify-center items-center space-x-2">
                <button
                    disabled={currentPage === 1}
                    onClick={handlePrevPage}
                    className={`${currentPage === 1 ? 'text-gray-600' : 'text-gray-400'} cursor-pointer`}
                >
                    <FaArrowLeft />
                </button>
                <p>{currentPage} of {totalPages}</p>
                <button
                    disabled={currentPage === totalPages}
                    onClick={handleNextPage}
                    className={`${currentPage === totalPages ? 'text-gray-600' : 'text-gray-400'} cursor-pointer`}
                >
                    <FaArrowRight />
                </button>
            </div>
            
            {showNav && (
                <NewBottomBar />
            )}
        </div>
    );
};

export default GameHist;