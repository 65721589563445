import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="bg-black text-white min-h-screen p-8 px-2">
      <div className="max-w-4xl mx-auto bg-[#1a1a1a] p-6 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-8">Chidiya Kabutar - Privacy Policy</h1>
        <p className="mb-6">
          At <strong>Chidiya Kabutar</strong>, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your data. By using our platform, you agree to the terms of this Privacy Policy.
        </p>

        <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
        <ul className="list-disc pl-5 mb-6">
          <li><strong>Personal Information:</strong> When you create an account, we collect basic personal details like your name, email address, and contact number.</li>
          <li><strong>Transaction Data:</strong> We collect data related to your transactions, including deposits, withdrawals, and betting activity.</li>
          <li><strong>Usage Data:</strong> We may collect information about how you use our platform, including device information, IP addresses, and browsing patterns.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
        <ul className="list-disc pl-5 mb-6">
          <li><strong>Account Management:</strong> We use your personal information to create and manage your account, and to verify your identity.</li>
          <li><strong>Transaction Processing:</strong> Your transaction data is used to process deposits, withdrawals, and bets.</li>
          <li><strong>Platform Improvement:</strong> Usage data helps us enhance your experience by improving our platform’s features and security.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">3. Data Protection</h2>
        <ul className="list-disc pl-5 mb-6">
          <li>We take reasonable measures to protect your personal information from unauthorized access, loss, or misuse. However, <strong>Chidiya Kabutar</strong> is not responsible for any unauthorized access to your data caused by user negligence or third-party breaches.</li>
          <li>We store your information in secure environments and regularly update our systems to ensure data protection.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">4. Third-Party Sharing</h2>
        <ul className="list-disc pl-5 mb-6">
          <li>We may share your information with <strong>trusted third-party service providers</strong> who assist us in running the platform (e.g., payment processors). However, your data will never be sold or shared for marketing purposes without your consent.</li>
          <li>In cases where we are legally required, we may share your information with authorities or regulators.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">5. User Responsibility</h2>
        <ul className="list-disc pl-5 mb-6">
          <li>You are responsible for keeping your login credentials confidential and ensuring that your account details are accurate. <strong>Chidiya Kabutar</strong> is not responsible for any issues arising from incorrect or outdated information provided by the user.</li>
          <li>By using our platform, you acknowledge that <strong>Chidiya Kabutar</strong> cannot be held responsible for any data breaches or privacy concerns that occur outside of our control, including actions caused by third-party services or your own device.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">6. Cookies and Tracking</h2>
        <ul className="list-disc pl-5 mb-6">
          <li>We use cookies to improve your experience on the platform, such as saving your preferences and enabling smooth gameplay.</li>
          <li>You may disable cookies in your browser settings, but this may limit certain features of the platform.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">7. Changes to the Privacy Policy</h2>
        <ul className="list-disc pl-5 mb-6">
          <li><strong>Chidiya Kabutar</strong> reserves the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page, and continued use of the platform constitutes acceptance of the updated policy.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">8. No Liability for Data Loss</h2>
        <ul className="list-disc pl-5 mb-6">
          <li><strong>Chidiya Kabutar</strong> is not liable for any loss of data or unauthorized access due to negligence or third-party activities. By using our platform, you agree to these terms and assume full responsibility for managing your account security.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">9. User Acknowledgment</h2>
        <ul className="list-disc pl-5 mb-6">
          <li>By using <strong>Chidiya Kabutar</strong>, you acknowledge and agree that <strong>Chidiya Kabutar</strong> holds no responsibility for any personal data loss or breach caused by factors beyond our control.</li>
          <li>You also agree that <strong>Chidiya Kabutar</strong> cannot be held liable for any legal issues, monetary loss, or privacy violations resulting from your use of the platform.</li>
        </ul>

        <p className="mt-8">
          If you have any concerns regarding our Privacy Policy, please contact our support team. By continuing to use our platform, you agree to the terms outlined above.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
