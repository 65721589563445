import { useState, useEffect } from 'react';
import { FaChevronRight, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { fetchAvatarImage } from '../api/avatarUtils'; 
import ChangePasswordModal from './ChangePasswordModal'; 
import NewBottomBar from './newBottomBar';
import api from '../api/utils';

const SettingsCenter = () => {
  const navigate = useNavigate();
  const [avatarImage, setAvatarImage] = useState(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [ckVersion, setCkVersion] = useState(''); 
  const [loading, setLoading] = useState(false); 
  const [language, setLanguage] = useState(() => {
    // Initialize state from localStorage, default to English
    const storedLanguage = localStorage.getItem('language_selected');
    return storedLanguage || 'English';
  });

  useEffect(() => {
    // Set default language to English if not already set in localStorage
    if (localStorage.getItem('language_selected') === null) {
      localStorage.setItem('language_selected', 'English');
    }

    const loadAvatarImage = async () => {
      try {
        const avatarImageUrl = await fetchAvatarImage();
        setAvatarImage(avatarImageUrl);
      } catch (error) {
        console.error('Error fetching avatar image:', error);
      }
    };

    const fetchCkVersion = async () => {
      setLoading(true); 
      const authToken = localStorage.getItem('auth_token');
      
      try {
        const response = await api.get('/user/get-version', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setCkVersion(response.data.version || 'Unknown'); 
      } catch (error) {
        console.error('Error fetching CK version:', error);
        setCkVersion('Error fetching version');
      } finally {
        setLoading(false);
      }
    };

    loadAvatarImage();
    fetchCkVersion();
  }, []);

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    localStorage.setItem('language_selected', selectedLanguage); 
  };

  return (
    <div className="h-screen p-2 pt-2">
      {/* Header */}
      <div className="flex justify-between items-center w-90 py-3 text-white pb-6">
        <div className='flex justify-center items-center'>
          <FaArrowLeft className='font-bold pr-2 text-2xl' onClick={() => navigate(-1)} />
          <h1 className="text-2xl font-bold">Settings Zone</h1>
        </div>
        <div className="rounded-full bg-blue-600 h-10 w-10 flex items-center justify-center">
          <img src={avatarImage} alt="user" className="rounded-full" />
        </div>
      </div>

      <div className='flex flex-col items-center w-full'>
        <div className="bg-[#282c2c] text-white w-full p-5 rounded-lg">
          <div className="flex items-center justify-between mb-4" onClick={() => navigate("/change-avatar")}>
            <img src={avatarImage} alt='avatarImage' className='w-10 h-10 rounded-full' />
            <div className="flex items-center">
              <span className="mr-3 font-bold">Change Avatar</span>
              <FaChevronRight />
            </div>
          </div>

          {/* Language Selection Dropdown */}
          <div className="flex justify-between items-center py-3 border-b border-gray-700">
            <span className='font-bold'>Select Language</span>
            <div className="flex items-center">
              <select
                value={language}
                onChange={handleLanguageChange}
                className="text-white rounded px-3 py-1 bg-transparent"
              >
                <option value="English" className='text-black'>English</option>
                <option value="Hindi" className='text-black'>Hindi</option>
              </select>
              {/* <FaChevronRight /> */}
            </div>
          </div>

          <div className="flex justify-between items-center py-3 border-b border-gray-700">
            <span className='font-bold'>CK Version</span>
            <span className="text-gray-400">
              {loading ? 'Loading...' : ckVersion}
            </span>
          </div>

          <div className="flex justify-between items-center py-3 border-b border-gray-700 cursor-pointer" onClick={() => setShowPasswordModal(true)}>
            <span className='font-bold'>Manage Password</span>
            <FaChevronRight />
          </div>

          <div className="flex justify-between items-center py-3" onClick={() => navigate('/payout-settings')}>
            <span className='font-bold'>Manage Payouts</span>
            <FaChevronRight />
          </div>
        </div>
      </div>

      {showPasswordModal && (
        <ChangePasswordModal onClose={() => setShowPasswordModal(false)} />
      )}
      <div className='text-white'>
        <NewBottomBar />
      </div>
    </div>
  );
};

export default SettingsCenter;
