import React from 'react'
import { FaArrowDown, FaArrowUp, FaHistory, FaRegUserCircle, FaMoneyBillWave,FaWallet, FaHome, FaGift, FaChartArea, FaUser } from 'react-icons/fa';
// import { , FaRegCreditCard, FaUniversity, FaUserFriends, , FaPowerOff } from 'react-icons/fa';
// import { } from 'react-icons/ai';
import {  AiOutlineSetting, AiOutlineRise, AiOutlineClockCircle, AiOutlineNotification } from 'react-icons/ai';
import {CiBoxes, CiGift} from 'react-icons/ci'
import {BiSolidUser} from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {MdLeaderboard} from 'react-icons/md'
import {IoIosWallet} from 'react-icons/io'

const NewBottomBar = () => {
    const navigate = useNavigate();
  return (      
    <div className="fixed bottom-2 left-0 right-0 bg-[#1a1a1a] p-3 px-8 rounded-full mx-1 mt-24">
      <div className="flex justify-between">
        <button className="flex flex-col items-center" onClick={() => navigate('/dashboard')}>
          <FaHome className="text-xl" />
          <p className="text-xs mt-1">Home</p>
        </button>
        <button className="flex flex-col items-center" onClick={() => navigate('/activity')}>
          <MdLeaderboard className="text-xl" />
          <p className="text-xs mt-1">Activity</p>
        </button>
        <button className="flex flex-col items-center" onClick={() => navigate('/earn')}>
          <FaGift className="text-xl" />
          <p className="text-xs mt-1">Earn</p>
        </button>
        <button className="flex flex-col items-center" onClick={() => navigate('/wallet')}>
          <IoIosWallet className="text-xl" />
          <p className="text-xs mt-1">Wallet</p>
        </button>
        <button className="flex flex-col items-center"onClick={() => navigate('/profile')}>
          <BiSolidUser className="text-xl" />
          <p className="text-xs mt-1">Profile</p>
        </button>
      </div>
    </div>
  )
}

export default NewBottomBar;