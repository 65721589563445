import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react';

const WebSocketContext = createContext(null);




export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

export const WebSocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const [error, setError] = useState(null);
  const ws = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const pingIntervalRef = useRef(null);

  const ping = useCallback(() => {
    if (ws.current?.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify({ type: 'ping' }));
    }
  }, []);

  const startPingInterval = useCallback(() => {
    pingIntervalRef.current = setInterval(ping, 30000); // Send a ping every 30 seconds
  }, [ping]);

  const stopPingInterval = useCallback(() => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
    }
  }, []);

  const connect = useCallback(async () => {
    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      setError('No auth token found');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/socket/token`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${authToken}` },
      });

      if (!response.ok) throw new Error('Failed to fetch WebSocket token');

      const { token: wsToken } = await response.json();
      if (!wsToken) throw new Error('WebSocket token missing in response');

      ws.current = new WebSocket(`wss://${process.env.REACT_APP_WS}/web/game/connect?token=${wsToken}`);

      ws.current.onopen = () => {
        setIsConnected(true);
        setError(null);
        startPingInterval();
      };

      ws.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === 'pong') {
            // Handle pong response if needed
            return;
          }
          setLastMessage(data);
        } catch (err) {
          console.error('Error parsing WebSocket message:', err);
        }
      };

      ws.current.onclose = (event) => {
        setIsConnected(false);
        stopPingInterval();
        if (event.code !== 1000) {
          setError(`WebSocket closed unexpectedly. Code: ${event.code}, Reason: ${event.reason}`);
        }
        reconnectTimeoutRef.current = setTimeout(connect, 3000);
      };

      ws.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        setError('WebSocket encountered an error');
      };
    } catch (error) {
      setError(`WebSocket connection error: ${error.message}`);
    }
  }, [startPingInterval, stopPingInterval]);

  const disconnect = useCallback(() => {
    if (ws.current) {
      ws.current.close(1000, 'Closing connection');
    }
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
    }
    stopPingInterval();
    setIsConnected(false);
    setError(null);
  }, [stopPingInterval]);

  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, [connect, disconnect]);

  const sendMessage = useCallback((message) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(message));
    } else {
      setError('WebSocket is not connected');
    }
  }, []);

  return (
    <WebSocketContext.Provider value={{ isConnected, lastMessage, sendMessage, disconnect, connect, error }}>
      {children}
    </WebSocketContext.Provider>
  );
};