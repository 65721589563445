import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa'; // Import Telegram icon
import { FaArrowLeft } from 'react-icons/fa';
import { GiMoneyStack } from 'react-icons/gi'; // Importing a money icon from react-icons
import { useNavigate } from 'react-router-dom';
import NewBottomBar from './newBottomBar';

const AgentPage = () => {
    const navigate = useNavigate();
  return (
    <div className="bg-black min-h-screen text-white py-10 px-5 pt-3">
    <div className='flex justify-start items-center pb-4'>
      <FaArrowLeft className='font-bold pr-2 text-2xl' 
        onClick={() => navigate(-1)} />
      {/* <h1 className="text-2xl font-bold">CK Deposit Bonus Offer</h1> */}
    </div>
      {/* Header Section */}
      <div className="mb-8">
        <h1 className="text-center text-4xl font-bold text-[#FBBF24]">CK Agent Program</h1>
        <p className="mt-4 text-lg text-gray-300">
          The <span className="text-[#fbbf24] font-semibold">CK Agent Program</span> is an exclusive opportunity for users to earn commission by referring others to play on 
          <span className="text-[#fbbf24]"> Chidiya Kabutar</span>. As a CK Agent, you can build your own network by inviting players to the platform. 
          Each time one of your referred users places a bet, you earn 
          <span className="font-bold text-[#34D399]"> 10% commission</span> on the total bet amount, regardless of whether they win or lose. 
          The more active players you refer, the more commission you can accumulate, creating a steady stream of income.
        </p>
      </div>

      {/* How It Works Section */}
      <div className="bg-[#1a1a1a] p-6 rounded-md mb-10">
        <h2 className="text-3xl font-semibold text-[#FBBF24] mb-6">How It Works:</h2>
        <ul className="list-decimal list-inside text-gray-300 space-y-4">
          <li>
            <span className="font-bold text-[#fbbf24]">Refer Players:</span> Share your unique referral link with potential players.
          </li>
          <li>
            <span className="font-bold text-[#fbbf24]">Earn Commission:</span> Once a player signs up using your link and places their first bet, you start earning 
            <span className="font-bold text-[#34D399]"> 10% commission</span> on every bet they make.
          </li>
          <li>
            <span className="font-bold text-[#fbbf24]">Daily Payouts:</span> Your commissions are calculated daily from 12:00 AM to 11:59 PM. The total earned commission is credited 
            to your wallet between 12:00 AM and 2:00 AM the following day.
          </li>
          <li>
            <span className="font-bold text-[#fbbf24]">Track Earnings:</span> Easily monitor your earnings and commission history within your account.
          </li>
        </ul>
      </div>

      {/* Support Section */}
      <div className="mb-10">
        <p className="text-lg text-gray-300">
          If you encounter any issues or have questions about the CK Agent Program, you can reach out for support via our 
          <span className="font-bold text-[#fbbf24]"> Telegram account</span>. We are always available to assist you with any concerns or problems you may face.
        </p>
      </div>

      {/* Telegram Links Section */}
      <div className="text-center mb-20">
        <h2 className="text-2xl font-bold text-[#FBBF24] mb-4">Start earning today with the CK Agent Program!</h2>
        <p className="text-gray-300 mb-6">Grow your income through referrals!</p>
        <div className="space-y-4">
          <a
            href="https://t.me/ChidiyaKabutarManager"
            className="bg-[#34D399] hover:bg-[#2d826f] text-black px-6 py-3 font-bold rounded-md flex items-center justify-center mx-auto w-64"
            target="_blank" 
            rel="noopener noreferrer"
          >
            Contact Agent on <FaTelegramPlane className="mx-2 text-xl" /> 
          </a>
          <a
            href="https://t.me/ChidiyaKabutarOfficial"
            className="bg-[#34D399] hover:bg-[#2d826f] text-black px-6 py-3 font-bold rounded-md flex items-center justify-center mx-auto w-64"
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FaTelegramPlane className="mr-2 text-xl" /> Join Official Channel
          </a>
        </div>
      </div>
      <NewBottomBar />
    </div>
  );
};

export default AgentPage;
