import React, { useEffect, useRef } from 'react';
import { FaDollarSign, FaClock, FaHandHoldingUsd, FaWallet } from 'react-icons/fa';
import { HiOutlineCurrencyRupee } from 'react-icons/hi';
import {IoIosTime, IoIosTimer, IoWalletOutline} from 'react-icons/io'

const DashHeader = ({ timeRemaining, betAmount, wallet_balance }) => {
    return (
        <div className="w-full p-3 pt-4 pb-4 bg-[#2D2D2D] rounded-lg flex justify-between items-center my-4 border-b-4 border-[#4f4f4f] m-2 mt-16">
            <div className="text-[1rem] flex flex-col items-center font-bold">
                ₹ {wallet_balance.toFixed(2)}
                <div className="flex justify-center items-center text-[0.6rem] mt-1">
                    <FaWallet className="text-[0.9rem] pr-1" /> Wallet Balance
                </div>
            </div>
            <div className="text-[1rem] flex flex-col items-center font-bold">
                {typeof timeRemaining === 'number' ? (
                    `${timeRemaining}s`
                ) : (
                    // <FaSpinner className="animate-spin text-[1rem]" />
                    `45s`
                )}
                <div className="flex justify-center items-center text-[0.6rem] mt-1">
                    <IoIosTimer className="text-[1rem] pr-1" /> Time Remaining
                </div>
            </div>
            <div className="text-[1rem] flex flex-col items-center font-bold">
                ₹ {betAmount}
                <div className="flex justify-center items-center text-[0.6rem] mt-1">
                    <HiOutlineCurrencyRupee className="text-[1.1rem] pr-1" /> Bet Amount
                </div>
            </div>
        </div>
    );
};

export default DashHeader;
