// avatarUtils.js

// Import avatar images
import avatar1 from '../components/images/avatar/1.png';
import avatar2 from '../components/images/avatar/2.png';
import avatar3 from '../components/images/avatar/3.png';
import avatar4 from '../components/images/avatar/4.png';
import avatar5 from '../components/images/avatar/5.png';
import avatar6 from '../components/images/avatar/6.png';
import avatar7 from '../components/images/avatar/7.png';
import avatar8 from '../components/images/avatar/8.png';
import avatar9 from '../components/images/avatar/9.png';
import avatar10 from '../components/images/avatar/10.png';
import avatar11 from '../components/images/avatar/11.png';
import avatar12 from '../components/images/avatar/12.png';
import avatar13 from '../components/images/avatar/13.png';
import avatar14 from '../components/images/avatar/14.png';
import avatar15 from '../components/images/avatar/15.png';

// Map avatar names to images
const avatarImages = {
  avatar1, avatar2, avatar3, avatar4, avatar5,
  avatar6, avatar7, avatar8, avatar9, avatar10,
  avatar11, avatar12, avatar13, avatar14, avatar15
};

// Define a function to get the token from local storage
const getToken = () => {
  return localStorage.getItem('auth_token'); // Replace 'auth_token' with the actual key used in your local storage
};

// Function to fetch avatar image based on API response
export const fetchAvatarImage = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/me`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log('API Response:', data); // Log the entire API response
    const avatarName = data.user.avatar;
    return avatarImages[avatarName] || avatar1; // Return the corresponding avatar image
  } catch (error) {
    console.error('Error fetching avatar image:', error);
    return avatar1; // Return default avatar in case of an error
  }
};
