import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { WebSocketProvider } from './hooks/websocket';
import Login from './components/LoginPage';
import Signup from './components/SignUp';
import Dashboard from './components/Dashboard';
import Layout from './layout';

import usePreventZoom from './hooks/zoom';
import Deposit from './components/DepoHist';
import Withdrawal from './components/Withdraw';

import UserStats from './components/UserStats';



import Sample from './components/Sample';
import UpdatedProfile from './components/updated_profile';
import UpdatedWallet from './components/updated_wallet';
import ReferAndEarn from './components/ReferEarn';
import Referrals from './components/YourReferral';
import Activity from './components/Activity';
import Updated_wh from './components/updated_wh';
import Updated_dh from './components/updated_dh';
import Updated_ch from './components/updated_ch';

import CommissionRatio from './components/CommissionRatio';
import InvitationRules from './components/InvitationRules';
import DepositZone from './components/DepositZone';
import Withdraw_zone from './components/new_withdraw_zone';
import SettingsCenter from './components/updated_settings';
import Notifications from './components/Notifications';
import EarningsPage from './components/YourEarnings';
import { DenominationProvider } from './hooks/denomination';
import AvatarSelector from './components/avatar';
import PayoutSettings from './components/payout_settings';
import GameHistory from './components/GameHistory';
import PrevHistory from './components/PrevHistory';
import TermsAndConditions from './components/TermsandCon';
import PrivacyPolicy from './components/PrivacyPolicy';
import BonusPage from './components/Bonus';
import AgentPage from './components/AgentPage';

function App() {
  usePreventZoom();

  return (
    <DenominationProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup/:referralCode" element={<Signup />} /> {/* You can remove this line if using query parameters */}
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            {/* <Route path="/updated-profile" element={<UpdatedProfile />} />
            <Route path="/updated-wallet" element={<UpdatedWallet />} /> */}
            <Route element={
                <WebSocketProvider>
                
                    <Layout />
                  
                </WebSocketProvider>
            }>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/wallet" element={<UpdatedWallet />} />
              <Route path="/deposit" element={<Deposit />} />
              <Route path="/withdraw" element={<Withdrawal />} />
              <Route path="/profile" element={<UpdatedProfile />} />
              <Route path="/user-stats" element={<UserStats />} />
              <Route path="/game-history" element={<GameHistory />} />
              <Route path="/bonus" element={<BonusPage />} />
              <Route path="/agent" element={<AgentPage />} />
              <Route path="/bet-history" element={<PrevHistory />} />
              {/* <Route path="/bet-history" element={<BetHistory />} /> */}
              <Route path="/deposit-history" element={<Updated_dh />} />
              <Route path="/withdraw-history" element={<Updated_wh />} />
              <Route path="/withdraw-zone" element={<Withdraw_zone />} />
              <Route path="/earn" element={<ReferAndEarn />} />
              {/* <Route path="/updated-wh" element={<Updated_wh />} />
              <Route path="/updated-dh" element={<Updated_dh />} /> */}
              <Route path="/commission-history" element={<Updated_ch />} />
              <Route path="/your-referrals" element={<Referrals />} />
              <Route path="/activity" element={<Activity />} />
              <Route path="/commission-ratio" element={<CommissionRatio />} />
              <Route path="/invitation-rules" element={<InvitationRules />} />
              <Route path="/deposit-zone" element={<DepositZone />} />
              <Route path="/settings-zone" element={<SettingsCenter />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/your-earnings" element={<EarningsPage />} />
              <Route path="/sample" element={<Sample />} />
              <Route path="/change-avatar" element={<AvatarSelector />} />
              <Route path="/payout-settings" element={<PayoutSettings />} />
              {/* Add other protected routes here */}
            </Route>
            <Route path="/" element={<Login />} /> {/* Redirect '/' to login */}
          </Routes>
        </div>
      </Router>
    </DenominationProvider>
  );
}

export default App;