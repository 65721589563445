import React, { useState, useEffect, useMemo } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import NewBottomBar from './newBottomBar';
import image1 from './images/ball.png';
import image2 from './images/balti.png';
import image3 from './images/diya.png';
import image4 from './images/cow.png';
import image5 from './images/kabutar.png';
import image6 from './images/kite.png';
import image7 from './images/lattu.png';
import image8 from './images/rabbit.png';
import image9 from './images/rose.png';
import image10 from './images/sun.jpg';
import image11 from './images/titli.png';
import image12 from './images/umbrella.png';

const YourHistory = ({ response, currentPage, handlePage, showNav = true, showBottom = true }) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);
  const [yourHistoryData, setYourHistoryData] = useState([]);
// // console.log(response);
  const items = useMemo(() => [
    { id: 1, label: 'छतरी', image: image12 },
    { id: 2, label: 'बॉल', image: image1 },
    { id: 3, label: 'सूरज', image: image10 },
    { id: 4, label: 'दिया', image: image3 },
    { id: 5, label: 'गाय', image: image4 },
    { id: 6, label: 'बाल्टी', image: image2 },
    { id: 7, label: 'पतंग', image: image6 },
    { id: 8, label: 'लट्टू', image: image7 },
    { id: 9, label: 'गुलाब', image: image9 },
    { id: 10, label: 'तितली', image: image11 },
    { id: 11, label: 'कबूतर', image: image5 },
    { id: 12, label: 'खरगोश', image: image8 }
  ], []);

  const getWinnerDetails = (winnerId) => {
    const winner = items.find(item => item.id === winnerId);
    return winner ? { name: winner.label, image: winner.image } : { name: 'Unknown', image: null };
  }

  useEffect(() => {
    if (response && response.data) {
      const totalCount = response.data.total_count || 0;
      const pages = Math.ceil(totalCount / 7); // Assuming 7 items per page

      const yourHistoryDataTo = Array.isArray(response.data.items)
        ? response.data.items.map(item => ({
            id: item.round,
            bets: item.bets || [],
            netOutcome: item.net_overall,
            winItem: items.find(i => i.id === item.win_item_id),
            net_overall_final: item.net_overall,
            winner_final: item.winner,
            win_amount_final: item?.win_amount,
            lose_amount_final: item?.loose_amount
          }))
        : [];
        // console.log(response);
      setYourHistoryData(yourHistoryDataTo);
      // if(pages > 10){
      //   pages = 10;
      // }
      setTotalPages(pages);
    } else {
      setError("Invalid response data");
    }
  }, [response, items]);

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      handlePage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePage(currentPage + 1);
    }
  };

  return (
    <div className="bg-black text-white p-4">
      <h1 className="text-xl font-bold px-2 rounded-t-lg bg-[#1a1a1a]">{showBottom && "Your History"}</h1>
      <div className="bg-[#1a1a1a] rounded-b-lg p-4 mb-4">
        {error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <table className="w-full table-auto">
            <thead className="text-yellow-500 border-b border-gray-500">
              <tr>
                <th className="py-2 text-left">Session ID</th>
                <th className="py-2 text-center">Amount</th>
                <th className="py-2 text-right">Bet Outcome</th>
              </tr>
            </thead>
            <tbody>
              {yourHistoryData.map((history, index) => {
                const totalBetAmount = history.bets.reduce((sum, bet) => sum + bet.amount, 0);

                return (
                  <React.Fragment key={index}>
                    <tr
                      className="border-b border-gray-700 cursor-pointer"
                      onClick={() => toggleRow(index)}
                    >
                      <td className="py-2 text-left">{history.id}</td>
                      <td className="py-2 text-center">₹{totalBetAmount}</td>
                      <td className="py-2 text-right flex justify-end items-center space-x-2">
                        {history.winItem && (
                          <React.Fragment>
                            <img src={history.winItem.image} alt={history.winItem.label} className="w-6 h-6 rounded-full" />
                            <span>{history.winItem.label}</span>
                          </React.Fragment>
                        )}
                        <span className="flex items-center">
                          <span className={`w-3 h-3 rounded-full ${history.net_overall_final > 0 ? 'bg-green-500' : 'bg-red-500'} mr-2`}></span>
                          <span>{history.net_overall_final > 0 ? `+₹${history.net_overall_final}` : `-₹${Math.abs(history.net_overall_final)}`}</span>
                        </span>
                      </td>
                    </tr>

                    {expandedRow === index && (
                      <tr className="bg-[#1a1a1a]">
                        <td colSpan="3" className="p-4">
                          <div className="flex flex-col items-start space-y-2">
                            <p><strong>Result:</strong><img className='w-8 h-8 rounded-full' src={getWinnerDetails(history.winner_final).image} alt={getWinnerDetails(history.winner_final).name} /></p>
                            <div>
                              <p><strong>Bet Outcome:</strong></p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              {history.bets.map((bet, betIndex) => {
                                const betItem = items.find(item => item.id === bet.blocks);
                                return betItem ? (
                                  <div key={betIndex} className="flex items-center space-x-1 p-1 rounded-lg text-[0.8rem]">
                                    <img src={betItem.image} alt={betItem.label} className="w-8 h-8 rounded-full" />
                                    <div>
                                      <p>{betItem.label}</p>
                                      <p>Bet: ₹{bet.amount}</p>
                                    </div>
                                  </div>
                                ) : null;
                              })}
                            </div>
                            <p><strong>Total Bets Placed:</strong> ₹{totalBetAmount}</p>
                            <p><strong>Total Win Amount:</strong> ₹{history.win_amount_final}</p>
                            <p><strong>Total Loss Amount:</strong> ₹{Math.abs(history.lose_amount_final)}</p>
                            <p><strong>Net Outcome: </strong> 
                              {history.net_overall_final > 0 ? 
                                `(Win) ₹${history.net_overall_final}` 
                                : 
                                `(Loss) ₹${Math.abs(history.net_overall_final)}`
                              }
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <div className="flex justify-center items-center space-x-2">
        <button
          disabled={currentPage === 1}
          onClick={handlePrevPage}
          className={`${currentPage === 1 ? 'text-gray-600' : 'text-gray-400'} cursor-pointer`}
        >
          <FaArrowLeft />
        </button>
        <p>{currentPage} of {totalPages ? totalPages : 1}</p>
        <button
          disabled={currentPage === totalPages}
          onClick={handleNextPage}
          className={`${currentPage === totalPages ? 'text-gray-600' : 'text-gray-400'} cursor-pointer`}
        >
          <FaArrowRight />
        </button>
      </div>
      {showNav && <NewBottomBar />}
    </div>
  );
};

export default YourHistory;