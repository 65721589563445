import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import api from '../api/utils';

// Import avatar images
import avatar1 from './images/avatar/1.png';
import avatar2 from './images/avatar/2.png';
import avatar3 from './images/avatar/3.png';
import avatar4 from './images/avatar/4.png';
import avatar5 from './images/avatar/5.png';
import avatar6 from './images/avatar/6.png';
import avatar7 from './images/avatar/7.png';
import avatar8 from './images/avatar/8.png';
import avatar9 from './images/avatar/9.png';
import avatar10 from './images/avatar/10.png';
import avatar11 from './images/avatar/11.png';
import avatar12 from './images/avatar/12.png';
import avatar13 from './images/avatar/13.png';
import avatar14 from './images/avatar/14.png';
import avatar15 from './images/avatar/15.png';

// Map avatar names to images
const avatarImages = {
  avatar1, avatar2, avatar3, avatar4, avatar5,
  avatar6, avatar7, avatar8, avatar9, avatar10,
  avatar11, avatar12, avatar13, avatar14, avatar15
};

const TopBackNav = ({ heading }) => {
  const navigate = useNavigate();
  const [walletBalance, setWalletBalance] = useState(null);
  const [avatarImage, setAvatarImage] = useState(avatar1); // Default to avatar1

  useEffect(() => {
    const fetchWalletBalance = async () => {
      try {
        const token = localStorage.getItem('auth_token');
        if (!token) {
          console.error('No auth_token found in localStorage');
          return;
        }
    
        // Send API request
        const { data } = await api.get('/user/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        console.log('API Response:', data);
    
        // Update avatar and wallet balance
        if (data && data.user) {
          const avatarName = data.user.avatar;
          setAvatarImage(avatarImages[avatarName] || avatar1);
    
          if (data.user.wallet && typeof data.user.wallet.balance === 'number') {
            setWalletBalance(data.user.wallet.balance);
          } else {
            console.error('Wallet balance is not a number:', data.user.wallet);
          }
        } else {
          console.error('Unexpected API response structure:', data);
        }
      } catch (error) {
        console.error('Error fetching wallet balance:', error.message);
      }
    };
    
    

    fetchWalletBalance();
  }, []);

  return (
    <div className="flex justify-between items-center p-4 pt-2">
      <div className='flex justify-between items-center'>
        <FaArrowLeft 
          className='font-bold pr-2 text-2xl'
          onClick={() => navigate(-1)} 
        />
        <h1 className="text-xl font-bold pl-2">{heading}</h1>
      </div>
      <div className="rounded-full bg-blue-600 h-10 w-10 flex items-center justify-center">
        <img
          src={avatarImage}
          alt="user"
          className="rounded-full"
        />
      </div>
    </div>
  );
};

export default TopBackNav;
