import React, { useState, useEffect } from 'react';
import { FaQrcode, FaCopy, FaArrowUp } from 'react-icons/fa';
import api from '../api/utils'; // Import the API utility
import BottomNav from './BottomNav';

const Deposit = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [activeTab, setActiveTab] = useState(0); // Start with the first tab
  const [amount, setAmount] = useState('');
  const [utrNumber, setUtrNumber] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [depositSuccess, setDepositSuccess] = useState('');
  const [depositError, setDepositError] = useState('');

  useEffect(() => {
    // Fetch payment methods from API
    const fetchPaymentMethods = async () => {
      try {
        const authToken = localStorage.getItem('auth_token');
        const response = await api.get('/user/payment-methods', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setPaymentMethods(response.data.payment_methods);
      } catch (error) {
        console.error('Error fetching payment methods:', error);
      }
    };

    fetchPaymentMethods();
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const authToken = localStorage.getItem('auth_token');
      const currentPaymentMethod = paymentMethods[activeTab];
      const response = await api.post('/user/deposit', {
        amount: parseInt(amount, 10),
        utr: utrNumber,
        payment_id: currentPaymentMethod.id,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      setDepositSuccess(response.data.message);
      setDepositError('');
    } catch (error) {
      setDepositError('Failed to create deposit request. Please try again.');
      setDepositSuccess('');
      console.error('Error making deposit request:', error);
    }
  };

  const copyToClipboard = (upiId) => {
    navigator.clipboard.writeText(upiId).then(() => {
      setCopySuccess(true); // Set success message after copying
      setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const currentAccount = paymentMethods[activeTab] || {};

  return (
    <div className="flex flex-col items-center h-full bg-gray-800 p-6 mb-8">
      <h1 className="text-white text-2xl font-bold mb-8">Payment Window</h1>

      <div className="w-full max-w-lg bg-gray-800 rounded-lg shadow-lg">
        <div className="flex rounded-b-lg">
          {paymentMethods.map((_, index) => (
            <button
              key={index}
              onClick={() => handleTabClick(index)}
              className={`flex-1 py-2 px-3 text-center text-white rounded-md mb-0 ${
                activeTab === index ? 'bg-gray-500 font-bold' : 'bg-gray-700'
              }`}
            >
              Acc {index + 1}
            </button>
          ))}
        </div>
      </div>

      <div className="bg-gray-700 mt-2 p-6 pt-2 rounded-lg shadow-lg w-full max-w-lg">
        <div className="mb-6 text-white">
          <div className="flex items-center justify-center mb-4">
            <div className="">
              <div className="text-lg font-semibold text-center mb-2">QR Code</div>
              {currentAccount.qr_code && (
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}/images/payment/${currentAccount.qr_code}`}
                  alt="QR Code"
                  className="w-48 h-48"
                />
              )}
            </div>
          </div>

          <div className="mb-4">
            <div className="text-sm text-center">Min Amount: ₹{currentAccount.min_amount}</div>
            <div className="text-sm text-center">Max Amount: ₹{currentAccount.max_amount}</div>
          </div>

          <div className="flex items-center justify-center mb-6">
            <span className="text-sm text-center mr-2">UPI ID:</span>
            <div className="bg-gray-700 text-white p-2 rounded-md flex items-center">
              <span className="mr-2">{currentAccount.upi_id || 'Not Available'}</span>
              {currentAccount.upi_id && (
                <FaCopy
                  className="text-gray-400 cursor-pointer hover:text-white"
                  onClick={() => copyToClipboard(currentAccount.upi_id)}
                />
              )}
            </div>

            {/* Copy success message */}
            {copySuccess && (
              <span className="ml-2 text-green-500 text-sm">Copied!</span>
            )}
          </div>

          <form onSubmit={handleSubmit} className=''>
            <div className="mb-4">
              <input
                type="text"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Amount"
                className="w-full px-3 py-2 rounded-md text-black"
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                value={utrNumber}
                onChange={(e) => setUtrNumber(e.target.value)}
                placeholder="UTR Number"
                className="w-full px-3 py-2 rounded-md text-black"
              />
            </div>
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-1 rounded-md flex items-center transition-colors duration-300 ease-in-out"
            >
              <FaArrowUp className="mr-2" />
              Deposit
            </button>
          </form>

          {/* Display deposit status message */}
          {depositSuccess && (
            <div className="mt-4 text-green-500">{depositSuccess}</div>
          )}
          {depositError && (
            <div className="mt-4 text-red-500">{depositError}</div>
          )}
        </div>
      </div>
      <BottomNav/>
    </div>
  );
};

export default Deposit;
