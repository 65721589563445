import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import api from './api/utils';

const Layout = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const checkAuthenticated = async () => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      return false;
    }

    try {
      const response = await api.get('/user/test-auth', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      localStorage.removeItem('auth_token');
      return false;
    }
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
    
      if (!['/login', '/signup'].includes(location.pathname)) {
        const authenticated = await checkAuthenticated();
        if (!authenticated) {
          navigate('/login');
        } else {
          setIsAuthenticated(true);
        }
      }
    };

    checkAuthStatus();
  }, [location, navigate]);

  if (!isAuthenticated && !['/login', '/signup'].includes(location.pathname)) {
    return null; // Optionally render a loading state while checking authentication
  }

  return <Outlet />; // Renders child routes
};

export default Layout;
