import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api/utils';
import TopNav from './TopNav';
import DashHeader from './DashHeader';
import Loader from './Loader';
import ScratchCard from './ScratchCard';
import { useWebSocket } from '../hooks/websocket';
import { useDenomination } from '../hooks/denomination';

import image1 from './images/ball.png';
import image2 from './images/balti.png';
import image3 from './images/diya.png';
import image4 from './images/cow.png';
import image5 from './images/kabutar.png';
import image6 from './images/kite.png';
import image7 from './images/lattu.png';
import image8 from './images/rabbit.png';
import image9 from './images/rose.png';
import image10 from './images/sun.jpg';
import image11 from './images/titli.png';
import image12 from './images/umbrella.png';
import GameHist from './GameHist';
import YourHistory from './YourHistory';
import { RiRefreshFill } from 'react-icons/ri';

import CountDownTimer from './CountDownTimer';

const LOCK_TIME = 6;
const TIME_MESSAGE_TIMEOUT = 4000;
const MUSIC_START_TIME = 43;

const Dashboard = () => {
  const navigate = useNavigate();
  const { isConnected, lastMessage, sendMessage, disconnect, connect } = useWebSocket();
  const { denomination, updateDenomination } = useDenomination();
  
  const [state, setState] = useState({
    bets: {},
    showChips: false,
    selectedCards: [],
    gameHistoryData: [],
    round: null,
  });

  const [timer, setTimer] = useState(null);
  const [lockGameUI, setLockGameUI] = useState(false);
  const [showScratchCard, setShowScratchCard] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [betAmount, setBetAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isWaitingForTimer, setIsWaitingForTimer] = useState(true);
  const [gameHistory, setGameHistory] = useState({});
  const [yourHistory, setYourHistory] = useState({});
  const [yourHistoryPage, setYourHistoryPage] = useState(1);
  const [gameHistoryPage, setGameHistoryPage] = useState(1);
  const [isMutedorNot, setisMutedorNot] = useState(false);

  const [zIndexControl, setZIndexControl] = useState(false);
  const [activeTabGame, setActiveTabGame] = useState('gameHistory');

  const audioRef = useRef(null);
  const timerRef = useRef(null);
  const lastRoundRef = useRef(null);
  const timeMessageTimeoutRef = useRef(null);

  const handleHistoryPage = useCallback((page) => {
    setYourHistoryPage(page);
  }, []);

  const handleGameHistoryPage = useCallback((page) => {
    setGameHistoryPage(page);
  }, []);

  const showToast = useCallback((message, type = 'error') => {
    const toast = document.createElement('div');
    toast.className = `fixed top-[20%] right-[18%] ${type === 'error' ? 'bg-red-500' : 'bg-green-500'} text-white px-4 py-2 rounded shadow-lg transition-opacity duration-300`;
    toast.innerHTML = `
      <div class="flex items-center">
        <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="${type === 'error' ? 'M6 18L18 6M6 6l12 12' : 'M9 12l2 2l4-4'}"></path>
        </svg>
        <span>${message}</span>
      </div>
    `;
    document.body.appendChild(toast);
    setTimeout(() => {
      toast.style.opacity = '0';
      setTimeout(() => {
        document.body.removeChild(toast);
      }, 300);
    }, 3000);
  }, []);

  const items = useMemo(() => [
    { id: 1, label: 'छतरी', image: image12 },
    { id: 2, label: 'बॉल', image: image1 },
    { id: 3, label: 'सूरज', image: image10 },
    { id: 4, label: 'दिया', image: image3 },
    { id: 5, label: 'गाय', image: image4 },
    { id: 6, label: 'बाल्टी', image: image2 },
    { id: 7, label: 'पतंग', image: image6 },
    { id: 8, label: 'लट्टू', image: image7 },
    { id: 9, label: 'गुलाब', image: image9 },
    { id: 10, label: 'तितली', image: image11 },
    { id: 11, label: 'कबूतर', image: image5 },
    { id: 12, label: 'खरगोश', image: image8 },
  ], []);

  const fetchInitialData = useCallback(async () => {
    const authToken = localStorage.getItem('auth_token');
    if (authToken === "") {
      navigate('/');
      return;
    }

    try {
      const [userDataResponse, yourHistoryData, gameHistoryData] = await Promise.all([
        api.get('/user/me', {
          headers: { Authorization: `Bearer ${authToken}` },
        }),
        api.get(`/user/get-bet-history?page=${yourHistoryPage}&page_size=7`, { headers: { Authorization: `Bearer ${authToken}` }}),
        api.get(`/user/bet-session?page=${gameHistoryPage}&page_size=7`, { headers: { Authorization: `Bearer ${authToken}` }})
      ]);

      setGameHistory(gameHistoryData);
      setYourHistory(yourHistoryData);
      setWalletBalance(userDataResponse.data.user.wallet.balance);
      setBetAmount(userDataResponse.data.user.exposure);
    } catch (error) {
      console.error('Failed to fetch initial data:', error);
      showToast('Failed to fetch initial data.', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [navigate, showToast, gameHistoryPage, yourHistoryPage]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const resetForNewRound = useCallback(() => {
    setLockGameUI(false);
    setState(prev => ({ ...prev, bets: {}, selectedCards: [] }));
  }, []);

  const handleTimeMessageTimeout = useCallback(() => {
    disconnect();
    connect();
  }, [disconnect, connect]);

  useEffect(() => {
    if (lastMessage) {
      if (lastMessage.type === 'time') {
        if (timeMessageTimeoutRef.current) {
          clearTimeout(timeMessageTimeoutRef.current);
        }
        timeMessageTimeoutRef.current = setTimeout(handleTimeMessageTimeout, TIME_MESSAGE_TIMEOUT);

        if (lastMessage.round !== lastRoundRef.current) {
          resetForNewRound();
          lastRoundRef.current = lastMessage.round;
        }
        setTimer(lastMessage.time);
        setState(prev => ({ ...prev, round: lastMessage.round }));

        if (isWaitingForTimer) {
          setIsWaitingForTimer(false);
        }
      } else if (lastMessage.type === 'winner') {
        fetchInitialData();
        setShowScratchCard(true);
        setTimeout(() => setShowScratchCard(false), 3000);
        setBetAmount(0);
      } else if (lastMessage.type === 'bet_request') {
        if (lastMessage.exposure !== undefined) {
          setBetAmount(lastMessage.exposure);
          showToast('Bet placed successfully!', 'success');
          fetchInitialData();
        } else {
          showToast('Error: Bet amount not received', 'error');
        }
      } else if (lastMessage.type === 'balance_update') {
        if (lastMessage.balance !== undefined) {
          setWalletBalance(lastMessage.balance);
        }
      }
    }

    return () => {
      if (timeMessageTimeoutRef.current) {
        clearTimeout(timeMessageTimeoutRef.current);
      }
    };
  }, [lastMessage, showToast, resetForNewRound, fetchInitialData, handleTimeMessageTimeout, isWaitingForTimer]);

  useEffect(() => {
    if (timer === null) return;
  
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  
    timerRef.current = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer > 0) {
          if (prevTimer <= LOCK_TIME) {
            setLockGameUI(true);
          }
          return prevTimer - 1;
        } else {
          clearInterval(timerRef.current);
          return null;
        }
      });
  
      if (audioRef.current && timer === MUSIC_START_TIME) {
        audioRef.current.play().catch(error => {
          console.error("Error playing audio:", error);
        });
      }
    }, 1000);
  
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [timer]);

  const handlePlaceBet = useCallback(() => {
    if (lockGameUI) return;

    const betData = Object.entries(state.bets).map(([label, amount]) => {
      const matchedItem = items.find(item => item.label === label);
      return matchedItem ? { block: matchedItem.id, amount: parseFloat(amount) } : null;
    }).filter(Boolean);

    if (betData.length === 0) {
      showToast('No bet data to place.', 'error');
      return;
    }

    sendMessage({
      bets: betData,
      type: 'bet_request',
      round: state.round,
    });

    setState(prev => ({ ...prev, bets: {}, selectedCards: [] }));
  }, [state.bets, state.round, sendMessage, items, lockGameUI, showToast]);

  const handleItemClick = useCallback((item) => {
    if (lockGameUI) return;

    setState((prev) => {
      const totalBetAmount = Object.values(prev.bets).reduce((acc, bet) => acc + bet, 0) + denomination;

      if (totalBetAmount > walletBalance) {
        showToast('Not enough money to place this bet', 'error');
        return prev;
      }

      const newBets = { ...prev.bets, [item.label]: (prev.bets[item.label] || 0) + denomination };
      const newSelectedCards = prev.selectedCards.includes(item.id)
        ? prev.selectedCards
        : [...prev.selectedCards, item.id];

      return { ...prev, bets: newBets, selectedCards: newSelectedCards };
    });
  }, [walletBalance, lockGameUI, showToast, denomination]);

  const handleResetChips = useCallback(() => {
    if (lockGameUI) return;
    setState((prev) => ({ ...prev, bets: {}, selectedCards: [] }));
  }, [lockGameUI]);

  const handleDenominationClick = useCallback((newDenomination) => {
    if (lockGameUI) return;
    updateDenomination(newDenomination);
    setState((prev) => ({ ...prev, showChips: false }));
  }, [lockGameUI, updateDenomination]);

  if (!isConnected || isWaitingForTimer) {
    return <Loader />;
  }

  return (
    <div className="bg-gradient-to-b from-brown-100 to-brown-100 min-h-screen w-full flex flex-col items-center p-2 text-white relative max-w-screen-sm mx-auto">
      <TopNav setZIndexControl={setZIndexControl} isMutedorNot={isMutedorNot} />
      <DashHeader
        timeRemaining={timer}
        betAmount={betAmount}
        wallet_balance={walletBalance}
      />
  
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="relative w-full">
            <div className={`grid grid-cols-4 gap-1 w-full relative bg-[#2D2D2D] p-2 rounded-t-lg ${lockGameUI ? 'pointer-events-none opacity-50' : ''} ${zIndexControl ? 'z-[-2]' : 'z-1'}`}>
              
              {showScratchCard && (
                <div className='absolute inset-0 bg-transparent bg-opacity-75 flex items-center justify-center z-50'>
                  <ScratchCard
                    winnerId={lastMessage?.winner}
                    onClose={() => setShowScratchCard(false)}
                    setZIndexControl={setZIndexControl}
                    gamesesh = {state.round }
                  />
                </div>
              )}

              {items.map((item) => (
                <div
                  key={item.id}
                  className={`relative flex flex-col items-center bg-black p-0.75 pb-0 rounded-sm ${state.selectedCards.includes(item.id) ? 'border-2 border-green-500 rounded-sm' : ''}`}
                  onClick={() => handleItemClick(item)}
                >
                  <div className="relative w-full">
                    <img src={item.image} alt={item.label} className="w-full h-24 pb-0.5 rounded-sm" />
                    {state.bets[item.label] > 0 && (
                      <div
                        className="absolute top-[-10%] right-[-10%] bg-black border-dotted border-2 border-white text-xs p-1 rounded-full text-center flex justify-center items-center z-10"
                        style={{ width: '2.5rem', height: '2.5rem', overflow: 'hidden' }}
                      >
                        <p>{state.bets[item.label]}</p>
                      </div>
                    )}
                  </div>
                  <p className="text-sm pb-1">{item.label}</p>
                </div>
              ))}
            </div>
  
            {lockGameUI && (
              <div className="absolute inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center z-20">
                <CountDownTimer timeRemaining={timer} />
              </div>
            )}
          </div>
  
          <div className="text-center font-bold w-full mb-4 text-[0.75rem] bg-[#2d2d2d] rounded-b-lg pb-2">
            Game Session: {state.round || 'Loading...'}
          </div>
  
          <div className='w-full mb-16'>
            <div className='flex justify-between bg-[#1a1a1a] mx-4 rounded-lg py-2 rounded-t-lg px-4'>
                <button
                    className={`text-md ${activeTabGame === 'gameHistory' ? 'text-yellow-500' : 'text-gray-400'}`}
                    onClick={() => setActiveTabGame('gameHistory')}
                >
                    Game History
                </button>
                <button
                    className={`text-md ${activeTabGame === 'yourHistory' ? 'text-yellow-500' : 'text-gray-400'}`}
                    onClick={() => setActiveTabGame('yourHistory')}
                >
                    Your History
                </button>
            </div>

            <div className='w-full mb-6'>
                {activeTabGame === 'gameHistory' && <GameHist response={gameHistory} handlePage={handleGameHistoryPage} currentPage={gameHistoryPage} showNav={true} showBottom={false} className="w-full" />}
                {activeTabGame === 'yourHistory' && <YourHistory response={yourHistory} handlePage={handleHistoryPage} currentPage={yourHistoryPage} showNav={true} showBottom={false} className="w-full" />}
            </div>
          </div>
  
          {state.showChips && (
            <div className="fixed bottom-32 left-2 bg-gray-800 p-2 rounded-lg flex space-x-2 overflow-x-auto">
              {[10, 20, 50, 100, 500, 1000].map((value) => (
                <button
                  key={value}
                  className={`text-white border-dotted border-white border-4 rounded-full ${denomination === value ? 'bg-yellow-500' : 'bg-black'}`}
                  style={{
                    width: '2.5rem',
                    height: '2.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: value >= 100 ? '0.7rem' : '0.9rem',
                    padding: '0.4rem',
                    borderRadius: '50%',
                  }}
                  onClick={() => handleDenominationClick(value)}
                >
                  {value}
                </button>
              ))}
            </div>
          )}
  
          <button
            className={`fixed bottom-20 left-2 bg-[black] border-white border-dotted border-4 font-bold text-white rounded-full z-1000 flex items-center justify-center ${lockGameUI ? 'opacity-50 cursor-not-allowed' : ''}`}
            style={{
              width: '3.5rem',
              height: '3.5rem',
              fontSize: '1.1rem',
              padding: 0,
            }}
            onClick={() => setState(prev => ({ ...prev, showChips: !prev.showChips }))}
            disabled={lockGameUI}
          >
            {denomination}
          </button>

          { Object.keys(state.bets).length > 0 &&
            <button
              className={`fixed bottom-36 right-2 bg-[black] border-white border-2 font-bold rounded-full z-1000 flex items-center justify-center text-center ${lockGameUI ? 'opacity-50 cursor-not-allowed' : ''}`}
              style={{
                width: '3.5rem',
                height: '3.5rem',
                fontSize: '0.9rem',
                padding: 0,
              }}
              onClick={handleResetChips}
              disabled={lockGameUI}
            >
              <RiRefreshFill className='text-[2rem]' />
            </button>
          }
          
          <button
            className={`fixed bottom-20 right-2 bg-[black] border-white border-2 font-bold rounded-full z-1000 flex items-center justify-center text-center ${lockGameUI ? 'opacity-50 cursor-not-allowed' : ''}`}
            style={{
              width: '3.5rem',
              height: '3.5rem',
              fontSize: '0.9rem',
              padding: 0,
            }}
            onClick={handlePlaceBet}
            disabled={lockGameUI}
          >
            Bet
          </button>
        </>
      )}
    </div>
  );
};

export default Dashboard;