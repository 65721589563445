import { useEffect } from 'react';

function usePreventZoom() {
  useEffect(() => {
    if (navigator.userAgent.indexOf('iPhone') > -1) {
      const viewport = document.querySelector("[name=viewport]");
      if (viewport) {
        viewport.setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1");
      }
    }
  }, []);
}

export default usePreventZoom;