import React, { useState, useEffect } from "react";

const Toast = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 2000); // Auto close the toast after 3 seconds

    return () => clearTimeout(timer); // Cleanup timer
  }, [onClose]);

  return (
    <div className="fixed top-8 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg z-50">
      {message}
    </div>
  );
};

export default Toast;
