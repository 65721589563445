import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import NewBottomBar from './newBottomBar';
import api from '../api/utils';

const Notifications = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNotification, setSelectedNotification] = useState(null); // State for the modal

  // Fetch notifications from the API
  useEffect(() => {
    const fetchNotifications = async () => {
      const token = localStorage.getItem('auth_token'); // Get the token from localStorage

      if (!token) {
        setError('No auth token found, please log in.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await api.get('/user/notifications', {
          headers: {
            Authorization: `Bearer ${token}`, // Use the token from localStorage
          },
        });
        setNotifications(response.data.items); // Assuming the response has a key 'items'
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load notifications');
        setIsLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  // Function to format date and show "X days ago" or exact date
  const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const notificationDate = new Date(timestamp * 1000); // Assuming the created_at is a UNIX timestamp
    const differenceInSeconds = (now - notificationDate) / 1000;

    const secondsInOneHour = 3600;
    const secondsInOneDay = 86400;
    const secondsInOneWeek = 7 * secondsInOneDay;

    if (differenceInSeconds < secondsInOneHour) {
      const hoursAgo = Math.floor(differenceInSeconds / 60 / 60);
      return `${hoursAgo}hr${hoursAgo > 1 ? 's' : ''} ago`;
    } else if (differenceInSeconds < secondsInOneDay) {
      const hoursAgo = Math.floor(differenceInSeconds / secondsInOneHour);
      return `${hoursAgo}hr${hoursAgo > 1 ? 's' : ''} ago`;
    } else if (differenceInSeconds < secondsInOneWeek) {
      const daysAgo = Math.floor(differenceInSeconds / secondsInOneDay);
      return `${daysAgo}d ago`;
    } else {
      // If older than a week, show the actual date in 'DD/MM/YYYY' format
      return new Intl.DateTimeFormat('en-GB').format(notificationDate);
    }
  };

  // Function to limit the text length for display
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  // Function to handle card click and open the popup
  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
  };

  // Function to close the popup
  const closeModal = () => {
    setSelectedNotification(null);
  };

  return (
    <div className="min-h-screen bg-black text-white p-6 pt-1 mb-20">
      {/* Header */}
      <div className="flex justify-between items-center p-4 pb-6 px-0">
        <div className="flex justify-center items-center">
          <FaArrowLeft className="font-bold pr-2 text-2xl" onClick={() => navigate(-1)} />
          <h1 className="text-2xl font-bold">Notification Center</h1>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="space-y-6">
        {isLoading ? (
          <p>Loading notifications...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          notifications.map((notification, index) => (
            <div
              key={index}
              className="bg-[#1a1a1a] p-4 rounded-lg cursor-pointer"
              onClick={() => handleNotificationClick(notification)}
            >
              <div className="flex justify-end items-center">
                <p className="text-[0.6rem] text-gray-500 font-semibold">
                  {formatTimeAgo(notification.created_at)}
                </p>
              </div>
              <h3 className="text-lg font-bold mb-2">{notification.title}</h3>
              <p>{truncateText(notification.message, 50)}</p> {/* Truncate message to 50 characters */}
            </div>
          ))
        )}
      </div>

      {/* Popup Modal for Full Notification */}
      {selectedNotification && (
        <>
          {/* Overlay */}
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={closeModal}></div>

          {/* Modal */}
          <div className="fixed inset-0 flex items-center justify-center z-50 mx-4">
            <div className="bg-[#1a1a1a] text-white rounded-lg p-6 px-2 max-w-md w-full shadow-lg px-4">
              <h3 className="text-lg font-bold mb-4">{selectedNotification.title}</h3>
              <p className='text-[0.9rem]'>{selectedNotification.message}</p>
              <div className="flex justify-end mt-4">
                <button
                  className="bg-blue-600 text-white px-4 py-2 rounded"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <NewBottomBar />
    </div>
  );
};

export default Notifications;
