import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from './logo.png';
import { FaTelegramPlane, FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import api from '../api/utils';
import Loader from './Loader';
import axios from 'axios';
import main_loader from './images/ck_loader.png'; // Main loader image

const Button = React.forwardRef(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? 'span' : 'button';
  const buttonClassNames = `inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 ${
    variant === 'ghost' ? 'hover:bg-accent hover:text-accent-foreground' : 'bg-primary text-primary-foreground shadow hover:bg-primary/90'
  } ${size === 'lg' ? 'h-8 rounded-md px-6' : 'h-8 px-3 py-1.5'} ${className}`;

  return <Comp className={buttonClassNames} ref={ref} {...props} />;
});

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={`flex h-10 w-full rounded-md border border-gray-300 bg-white bg-opacity-40 px-3 py-2 text-sm shadow-sm transition-colors placeholder:text-gray-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
      ref={ref}
      {...props}
    />
  );
});

const Login = () => {
  const [formData, setFormData] = useState({
    emailOrPhone: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const authToken = localStorage.getItem('auth_token');
    if (authToken) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isEmail = (value) => value.includes('@');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    setNotFound(false);

    const { emailOrPhone, password } = formData;
    const loginData = isEmail(emailOrPhone)
      ? { email: emailOrPhone, password }
      : { phone: emailOrPhone, password };

    try {
      const response = await api.post('/user/login', loginData);
      if (response.data && response.data.token) {
        localStorage.setItem('auth_token', response.data.token);
        navigate('/dashboard');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          setNotFound(true);
        } else {
          setError(error.response?.data?.message || 'An unexpected error occurred. Please try again.');
        }
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      console.error('Login error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showLoader ? (
        <div className="flex items-center justify-center min-h-screen bg-black p-4">
          <img 
            src={main_loader} 
            alt="Loading..." 
            className="h-70 w-70 sm:h-70 sm:w-70 md:h-80 md:w-64 lg:h-50 lg:w-50 animate-pulse"
          />
        </div>
      ) : (
        <div className="bg-[#1c2120] min-h-screen w-full flex flex-col items-center justify-center px-4 py-12 pt-0">
          <div className="w-full max-w-md">
            <img src={Logo} width={150} alt="Chidiya Kabutar logo" className="mx-auto mb-0 w-48 h-48" />
            <h2 className="text-center text-2xl font-bold text-white">WELCOME BACK,</h2>
            <h4 className="text-center text-md font-bold text-white">Lets Get Started</h4>
            <div className="mx-4 p-8 mt-4 border border-white border-opacity-20 rounded-xl bg-white bg-opacity-15">
              <h2 className="text-2xl font-bold text-center mb-6 text-white">LOGIN</h2>
              {isLoading ? (
                <Loader />
              ) : (
                <form onSubmit={handleSubmit} className="space-y-3">
                  <Input
                    type="text"
                    name="emailOrPhone"
                    value={formData.emailOrPhone}
                    onChange={handleInputChange}
                    className="bg-white bg-opacity-40 text-bold text-white placeholder:text-white placeholder:text-bold"
                    placeholder="Email or Phone Number"
                    required
                  />
                  <div className="relative">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={formData.password}
                      className="bg-white bg-opacity-40 text-bold text-white placeholder:text-white placeholder:text-bold"
                      onChange={handleInputChange}
                      placeholder="Password"
                      required
                    />
                    <button
                      type="button"
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? <FaEyeSlash className="text-white" /> : <FaEye className="text-white" />}
                    </button>
                  </div>
                  {error && (
                    <div className="text-center text-red-500">
                      <p className="text-sm">{error}</p>
                    </div>
                  )}
                  {notFound && (
                    <div className="text-center text-red-300">
                      <p className="text-sm mb-2">No Account Found or Disabled. If you believe your account has been disabled, please contact our Telegram support.</p>
                      <a
                        href="https://t.me/ChidiyaKabutarManager"
                        className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center justify-center mx-auto mt-2"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTelegramPlane className="mr-2" /> Contact Support
                      </a>
                    </div>
                  )}
                  <Button
                    className="w-full bg-gradient-to-r from-green-400 to-green-500 text-white py-5"
                    type="submit"
                  >
                    Login
                  </Button>
                </form>
              )}
              <div className="mt-6 text-center text-white">
                <p className="text-sm">
                  <button
                    onClick={handleForgotPassword}
                    className="text-blue-400 hover:underline"
                  >
                    Forgot Password?
                  </button>
                </p>
                <p className="text-sm">
                  Don't have an account?{' '}
                  <Link to="/signup" className="text-blue-400 hover:underline">
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </div>

          {/* Forgot Password Popup */}
          {showPopup && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="bg-[#1a1a1a] p-6 rounded-lg shadow-lg w-80 text-center text-white">
                <h3 className="text-lg font-bold mb-4">Reset Password</h3>
                <p className="text-sm mb-4">
                  To reset your password, please contact our 24x7 support on telegram. 
                  Click the link below to join:
                </p>
                <a
                  href="https://t.me/ChidiyaKabutarManager"
                  className="bg-[#34D399] hover:bg-[#2d826f] text-black px-6 py-3 font-bold rounded-md flex items-center justify-center mx-auto w-64"
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <FaTelegramPlane className="mr-2 text-lg" /> Contact Support
                </a>
                <div className="mt-4">
                  <Button onClick={closePopup} className="bg-red-500 text-white">
                    Close
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Login;
