import React from 'react';
import { FaUserAlt, FaInfoCircle, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import NewBottomBar from './newBottomBar';
import TopBackNav from './TopBackNav';

const InvitationRules = () => {
    const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-black text-white p-6 pt-1 mb-20">
      {/* Header */}
      <TopBackNav heading='Invitation Rules' />

      {/* Subtitle Section */}
      <div className="mb-6">
        <div className="flex items-center mb-2">
          <h2 className="text-xl font-bold pl-3 border-l-4 border-yellow-500">Detailed Rules for Referral and Commission System</h2>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="space-y-6">
        {/* Invitation Rules */}
        <div className="bg-[#1a1a1a] p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-4">Invitation Rules</h3>
          <ul className="list-disc list-inside text-gray-300">
            <li>Users can invite others to join the platform using their unique referral link or code.</li>
            <li>Every successful signup using the referral link will add the new user to the agent’s list of referrals.</li>
            <li>There is no limit to the number of users that can be referred.</li>
          </ul>
        </div>

        {/* Referral Commission */}
        <div className="bg-[#1a1a1a] p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-4">Referral Commission</h3>
          <ul className="list-disc list-inside text-gray-300">
            <li>A referred user is considered active only when they complete their first recharge.</li>
            <li>Once the first recharge is completed, the referred user is added to the referrer’s commission list.</li>
            <li>No commission is given for just a sign-up.</li>
            <li>No commission is earned on the first recharge itself.</li>
          </ul>
        </div>

        {/* Bet Commission Calculation */}
        <div className="bg-[#1a1a1a] p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-4">Bet Commission Calculation</h3>
          <ul className="list-disc list-inside text-gray-300">
            <li>On every bet placed by the referred user, the referrer earns 10% of the bet amount, irrespective of whether the referred user wins or loses the bet.</li>
            <li>This commission is calculated in real-time for each bet played by the referred user.</li>
            <li>The 10% commission applies to each individual bet made by any referred user.</li>
          </ul>
        </div>

        {/* Daily Commission Calculation */}
        <div className="bg-[#1a1a1a] p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-4">Daily Commission Calculation</h3>
          <ul className="list-disc list-inside text-gray-300">
            <li>The commission for each day is calculated starting from 12:00 AM (midnight) and ends at 11:59 PM the same day.</li>
            <li>Once the day's commission is calculated at the end of the day, the total commission is credited to the agent’s wallet.</li>
          </ul>
        </div>

        {/* Commission Credit Timing */}
        <div className="bg-[#1a1a1a] p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-4">Commission Credit Timing</h3>
          <ul className="list-disc list-inside text-gray-300">
            <li>The commission earned throughout the day is credited to the agent’s wallet between 12:00 AM and 2:00 AM the following day.</li>
            <li>Agents can view their commission history at any time to track the status of their commission credits.</li>
            <li>The history will provide a detailed breakdown of all transactions, including pending, successful, and failed commission credits.</li>
          </ul>
        </div>

        {/* Platform Authority (CK) */}
        <div className="bg-[#1a1a1a] p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-4">Platform Authority (CK - Chidiya Kabutar)</h3>
          <ul className="list-disc list-inside text-gray-300">
            <li>The final authority for all commission-related actions lies with the CK platform.</li>
            <li>If any user is found to be misusing or exploiting the system, they will be immediately disabled without any warnings or questions asked.</li>
            <li>CK reserves the right to monitor and take actions to maintain system integrity, including but not limited to disabling users who violate the rules.</li>
          </ul>
        </div>
      </div>
      <NewBottomBar />
    </div>
  );
};

export default InvitationRules;
