import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { GiMoneyStack } from 'react-icons/gi'; // Importing a money icon from react-icons
import { useNavigate } from 'react-router-dom';
import NewBottomBar from './newBottomBar';

const BonusPage = () => {
    const navigate = useNavigate();
  return (
    <div className="bg-black min-h-screen text-white py-10 px-5 pt-3">
    <div className='flex justify-start items-center pb-4'>
      <FaArrowLeft className='font-bold pr-2 text-2xl' 
        onClick={() => navigate(-1)} />
      {/* <h1 className="text-2xl font-bold">CK Deposit Bonus Offer</h1> */}
    </div>
      {/* Header Section */}
      <div className="mb-8 text-left">
        <h1 className="text-4xl font-bold text-[#FBBF24] text-center">CK Deposit Bonus Offer</h1>
        <p className="mt-4 text-lg text-gray-300">
          At <span className="text-[#fbbf24] font-semibold">Chidiya Kabutar</span>, every time you deposit ₹1,000 or more, 
          you will receive an additional <span className="font-bold text-[#34D399]">5% bonus</span> on the total deposit amount.
          This bonus will be automatically credited to your account, giving you even more chances to play and win!
        </p>
      </div>

      {/* Bonus Table Section */}
      <div className="w-full overflow-x-auto">
        <table className="table-auto border-collapse w-full bg-[#1a1a1a] rounded-md">
          <thead className="bg-[#282c2c]">
            <tr className="text-left">
              <th className="px-4 py-2 text-yellow-400">Deposit Amount</th>
              <th className="px-4 py-2 text-yellow-400">Bonus Percentage</th>
              <th className="px-4 py-2 text-yellow-400">Bonus Amount</th>
              <th className="px-4 py-2 text-yellow-400">Total with Bonus</th>
            </tr>
          </thead>
          <tbody>
            {/* Table rows */}
            {[
              { deposit: '₹1,000', bonusPercent: '5%', bonusAmount: '₹50', total: '₹1,050' },
              { deposit: '₹5,000', bonusPercent: '5%', bonusAmount: '₹250', total: '₹5,250' },
              { deposit: '₹10,000', bonusPercent: '5%', bonusAmount: '₹500', total: '₹10,500' },
              { deposit: '₹50,000', bonusPercent: '5%', bonusAmount: '₹2,500', total: '₹52,500' },
              { deposit: '₹1,00,000', bonusPercent: '5%', bonusAmount: '₹5,000', total: '₹1,05,000' }
            ].map((row, index) => (
              <tr key={index} className="bg-[#282c2c] hover:bg-[#1a1a1a]">
                <td className="border px-4 py-2">{row.deposit}</td>
                <td className="border px-4 py-2">{row.bonusPercent}</td>
                <td className="border px-4 py-2">{row.bonusAmount}</td>
                <td className="border px-4 py-2">{row.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Note Section */}
      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">Note:</h2>
        <ul className="list-disc list-inside text-gray-300">
          <li>The bonus applies only to deposits of <span className="font-bold text-[#fbbf24]">₹1,000 or more</span>.</li>
          <li>The bonus is credited instantly to your account once the deposit is confirmed.</li>
        </ul>
      </div>

      {/* Call to Action Section */}
      <div className="mt-10 text-center mb-20">
        <button className="bg-[#34D399] hover:bg-[#2d826f] text-black px-6 py-3 font-bold rounded-md flex items-center justify-center mx-auto" onClick={() => navigate('/deposit-zone')}>
          <GiMoneyStack className="mr-2 text-2xl" /> Take Advantage of this Offer
        </button>
      </div>
      <NewBottomBar />
    </div>
  );
};

export default BonusPage;
