// src/context/DenominationContext.js
import React, { createContext, useState, useContext } from 'react';

const DenominationContext = createContext();

export const useDenomination = () => {
  return useContext(DenominationContext);
};

export const DenominationProvider = ({ children }) => {
  const [selectedDenomination, setSelectedDenomination] = useState(10);

  return (
    <DenominationContext.Provider value={{ selectedDenomination, setSelectedDenomination }}>
      {children}
    </DenominationContext.Provider>
  );
};
