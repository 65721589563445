import React, { useState } from "react";
import { FaCheck, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import avatar1 from './images/avatar/1.png';
import avatar2 from './images/avatar/2.png';
import avatar3 from './images/avatar/3.png';
import avatar4 from './images/avatar/4.png';
import avatar5 from './images/avatar/5.png';
import avatar6 from './images/avatar/6.png';
import avatar7 from './images/avatar/7.png';
import avatar8 from './images/avatar/8.png';
import avatar9 from './images/avatar/9.png';
import avatar10 from './images/avatar/10.png';
import avatar11 from './images/avatar/11.png';
import avatar12 from './images/avatar/12.png';
import avatar13 from './images/avatar/13.png';
import avatar14 from './images/avatar/14.png';
import avatar15 from './images/avatar/15.png';
import NewBottomBar from "./newBottomBar";
import Toast from './Toast';
import api from '../api/utils';

const avatarNames = [
  'avatar1', 'avatar2', 'avatar3', 'avatar4', 'avatar5',
  'avatar6', 'avatar7', 'avatar8', 'avatar9', 'avatar10',
  'avatar11', 'avatar12', 'avatar13', 'avatar14', 'avatar15'
];

const avatars = [
  avatar1, avatar2, avatar3, avatar4, avatar5,
  avatar6, avatar7, avatar8, avatar9, avatar10,
  avatar11, avatar12, avatar13, avatar14, avatar15
];

const AvatarSelector = () => {
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const updateAvatar = async (avatarName) => {
    setIsLoading(true);
    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      console.error('No auth token found');
      setIsLoading(false);
      return;
    }

    try {
      const response = await api.patch('/user/update', 
        { avatar: avatarName },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        }
      );

      if (response.status === 200) {
        setShowToast(true);
        setTimeout(() => {
          navigate('/profile');
        }, 1000);
      } else {
        console.error('Failed to update avatar');
        setShowToast(true); // Show error toast
      }
    } catch (error) {
      console.error('Error updating avatar:', error);
      setShowToast(true); // Show error toast
    } finally {
      setIsLoading(false);
    }
  };

  const handleAvatarClick = (index) => {
    if (isLoading) return; // Prevent multiple clicks while loading
    setSelectedAvatar(index);
    const avatarName = avatarNames[index];
    updateAvatar(avatarName);
  };

  return (
    <div className="flex justify-center items-center bg-black text-white">
      <div className="p-4 bg-black rounded-lg pb-0 mb-24">
        <div className='flex text-left justify-start items-center mb-4'>
          <FaArrowLeft className='font-bold pr-2 text-2xl cursor-pointer' 
            onClick={() => navigate(-1)} />
          <h1 className="text-2xl font-bold pl-1">Select Avatar</h1>
        </div>
        <div className="grid grid-cols-3 gap-4 p-4 px-2 pt-1">
          {avatars.map((avatar, index) => (
            <div
              key={index}
              className={`relative p-1 rounded-lg cursor-pointer ${
                selectedAvatar === index ? "border-2 border-white" : "border-2 border-gray-400"
              } ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={() => handleAvatarClick(index)}
            >
              <img
                src={avatar}
                alt={`Avatar ${index + 1}`}
                className="w-24 h-24 object-cover rounded-md"
              />
              {selectedAvatar === index && (
                <div className="absolute top-0 left-0 bg-black bg-opacity-50 p-1 rounded-full">
                  <FaCheck className="text-white" />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {showToast && <Toast message="Avatar updated successfully!" onClose={() => setShowToast(false)} />}
      {isLoading && <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
      </div>}
      <NewBottomBar />
    </div>
  );
};

export default AvatarSelector;