import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Logo from './logo.png'; // Replace with your logo path
import axios from 'axios';
import api from '../api/utils';
import { FaArrowLeft, FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons

const Button = React.forwardRef(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? 'span' : 'button';
  const buttonClassNames = `inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 ${
    variant === 'ghost' ? 'hover:bg-accent hover:text-accent-foreground' : 'bg-primary text-primary-foreground shadow hover:bg-primary/90'
  } ${size === 'lg' ? 'h-8 rounded-md px-6' : 'h-8 px-3 py-1.5'} ${className}`;

  return <Comp className={buttonClassNames} ref={ref} {...props} />;
});

const Input = React.forwardRef(({ className, type, disabled = false, ...props }, ref) => {
  return (
    <input
      type={type}
      className={`flex h-12 w-full rounded-lg border border-gray-400 bg-[#2d2d2d] px-4 py-2 text-sm placeholder-gray-400 text-white shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
      ref={ref}
      disabled={disabled}
      {...props}
    />
  );
});

const Message = ({ message, type }) => {
  const baseStyle = 'text-center text-sm mt-4';
  const typeStyles = {
    success: 'text-green-500',
    error: 'text-red-500',
  };

  return message && <div className={`${baseStyle} ${typeStyles[type]}`}><p>{message}</p></div>;
};

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isReferralCodeDisabled, setIsReferralCodeDisabled] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const referralCodeParam = queryParams.get('referralCode');
    if (referralCodeParam) {
      setReferralCode(referralCodeParam);
      setIsReferralCodeDisabled(true);
    }
  }, [location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ text: '', type: '' });

    if (!acceptTerms) {
      setMessage({ text: 'You must accept the terms and conditions.', type: 'error' });
      return;
    }

    setIsLoading(true);

    try {
      await api.post('user/signup', {
        name,
        email,
        password,
        phone,
        referral_code: referralCode
      });

      setMessage({ text: 'Signup successful! Redirecting...', type: 'success' });
      setTimeout(() => navigate('/'), 2000);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setMessage({
          text: error.response?.data?.message || 'An unexpected error occurred. Please try again.',
          type: 'error'
        });
      } else {
        setMessage({ text: 'An unexpected error occurred. Please try again.', type: 'error' });
      }
      console.error('Signup error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-[#1c2120] min-h-screen w-full flex flex-col items-center justify-center px-4 py-12 pt-4">
      <div className="w-full max-w-sm">
        <div className="flex justify-between items-center p-4 pt-0 pb-6 px-0">
          <div className="flex justify-center items-center">
            <FaArrowLeft className="font-bold pr-2 text-2xl text-white" onClick={() => navigate(-1)} />
            <h1 className="text-2xl font-bold text-white">Create New Account</h1>
          </div>
        </div>
        <img src={Logo} width={150} alt="Chidiya Kabutar logo" className="mx-auto mb-0 w-48 h-48" />
        <h2 className="text-center text-md font-bold text-white">PLAY BIG, WIN BIG</h2>
        <div className="p-6 bg-white bg-opacity-15 rounded-xl shadow-md pt-0 mt-4 mx-4">
          <h2 className="text-center text-3xl font-bold text-white py-4">SIGN UP</h2>
          <form onSubmit={handleSubmit} className="space-y-3">
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
              className="bg-white bg-opacity-40 text-bold text-white placeholder:text-white placeholder:text-bold"
              required
            />
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="bg-white bg-opacity-40 text-bold text-white placeholder:text-white placeholder:text-bold"
              required
            />
            <div className="relative">
              <Input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="bg-white bg-opacity-40 text-bold text-white placeholder:text-white placeholder:text-bold"
                required
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? <FaEyeSlash className="text-white" /> : <FaEye className="text-white" />}
              </button>
            </div>
            <Input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Phone"
              className="bg-white bg-opacity-40 text-bold text-white placeholder:text-white placeholder:text-bold"
              required
            />
            <Input
              type="text"
              value={referralCode}
              id="referralCode"
              onChange={(e) => setReferralCode(e.target.value)}
              placeholder="Referral Code (optional)"
              className="bg-white bg-opacity-40 text-bold text-white placeholder:text-white placeholder:text-bold"
              disabled={isReferralCodeDisabled}
            />
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="acceptTerms"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                required
              />
              <label htmlFor="acceptTerms" className="text-sm text-white">
                I accept the{' '}
                <Link to="/terms" className="text-sm text-blue-400 hover:underline">
                  terms and conditions
                </Link>
              </label>
            </div>
            <Button
              className="w-full bg-gradient-to-r from-green-400 to-green-500 text-white py-5 rounded-md"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? 'Signing Up...' : 'SIGN UP'}
            </Button>
          </form>
          <Message message={message.text} type={message.type} />
          <div className="text-center text-white mt-4">
            <p>Already Registered? <Link to="/" className="text-orange-400">Log in here.</Link></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
