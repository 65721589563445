import React, { createContext, useState, useContext, useEffect } from 'react';

const DenominationContext = createContext();

export const DenominationProvider = ({ children }) => {
  const [denomination, setDenomination] = useState(() => {
    const savedDenomination = localStorage.getItem('selectedDenomination');
    return savedDenomination ? parseInt(savedDenomination, 10) : 10;
  });

  useEffect(() => {
    localStorage.setItem('selectedDenomination', denomination.toString());
  }, [denomination]);

  const updateDenomination = (newDenomination) => {
    setDenomination(newDenomination);
  };

  return (
    <DenominationContext.Provider value={{ denomination, updateDenomination }}>
      {children}
    </DenominationContext.Provider>
  );
};

export const useDenomination = () => {
  const context = useContext(DenominationContext);
  if (!context) {
    throw new Error('useDenomination must be used within a DenominationProvider');
  }
  return context;
};