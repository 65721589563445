import React from 'react';
import {
  FaHome, FaHistory, FaWallet, FaTrophy, FaUser
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const BottomNav = () => {
  const navigate = useNavigate(); // Use the navigate hook

  return (
    <div className="fixed bottom-0 w-full bg-[#59322a] flex justify-around text-white p-1 border-t border-[#4f4f4f]">
      <button className="flex flex-col items-center text-[0.6rem]" onClick={() => navigate('/dashboard')}>
        <FaHome className="text-lg" />
        Home
      </button>
      <button className="flex flex-col items-center text-[0.6rem]" onClick={() => navigate('/activity')}>
        <FaHistory className="text-lg" />
        Activity
      </button>
      <button className="flex flex-col items-center text-[0.6rem]" onClick={() => navigate('/earn')}>
        <FaTrophy className="text-lg" />
        Earn
      </button>
      <button className="flex flex-col items-center text-[0.6rem]" onClick={() => navigate('/wallet')}>
        <FaWallet className="text-lg" />
        Wallet
      </button>
      <button className="flex flex-col items-center text-[0.6rem]" onClick={() => navigate('/profile')}>
        <FaUser className="text-lg" />
        Profile
      </button>
    </div>
  );
};

export default BottomNav;
