import React from 'react';
import { FaUserAlt, FaInfoCircle, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import NewBottomBar from './newBottomBar';
import TopBackNav from './TopBackNav';

const CommissionRatio = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-black text-white p-6">
      {/* Header */}
      <TopBackNav heading='Commission Ratio' />

      {/* Introduction Section */}
      <div className="mb-6">
        <div className="flex items-center mb-2">
          <h2 className="text-xl font-bold pl-3 border-l-4 border-yellow-500">Introduction to Commission Ratio</h2>
        </div>
        <p className="text-gray-300 leading-relaxed text-[0.9rem]">
          The commission ratio is a key component in the Chidiya Kabutar that determines how much a referring user (or agent) earns from the bets placed by their referred users. This page will break down how the commission works, with real-world examples, to make it easy for you to understand and track your earnings.
        </p>
      </div>

      {/* How Commission Works Section */}
      <div className="bg-[#1a1a1a] p-4 rounded-lg mb-6">
        <h3 className="text-lg font-semibold mb-4">How Commission Works</h3>
        
        {/* Referred User Activation */}
        <div className="mb-4">
          <h4 className="font-semibold">1) Referred User Activation</h4>
          <ul className="list-disc list-inside text-gray-300 text-[0.9rem]">
            <li>
              When a user you refer signs up, they are added to your referral list, but they do not contribute to your commission until they complete their first recharge.
            </li>
            <li>
              Once they make a recharge, they are officially added to your commission/earning list, and any future bets they place will start earning you commission.
            </li>
          </ul>
        </div>
        
        {/* Earning 10% on Every Bet */}
        <div>
          <h4 className="font-semibold">2) Earning 10% on Every Bet</h4>
          <ul className="list-disc list-inside text-gray-300 text-[0.9rem]">
            <li>From the moment a referred user places a bet, you, as the referrer, earn 10% of the bet amount.</li>
            <li>This 10% is earned regardless of whether the referred user wins or loses the bet.</li>
          </ul>
        </div>
      </div>

      {/* Real-World Scenario Section (Replaced Table) */}
      <div className="bg-[#1a1a1a] p-4 rounded-lg mb-6">
        <h3 className="text-lg font-semibold mb-4">Real-World Scenario Example</h3>
        <p className="text-gray-300 text-[0.9rem] mb-4">
          Let’s say you referred <span className="font-bold">5 users</span>. Here’s a potential breakdown:
        </p>
        <div className="overflow-x-auto">
          <table className="w-full text-gray-300 border border-gray-500 rounded-lg">
            <thead>
              <tr className="bg-[#2b2b2b] text-white">
                <th className="py-2 px-4 border border-gray-300">Referred User</th>
                <th className="py-2 px-4 border border-gray-300">Bet Amount</th>
                <th className="py-2 px-4 border border-gray-300">Your Commission (10%)</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border border-gray-300">
                <td className="py-2 px-4 border border-gray-300">User 1</td>
                <td className="py-2 px-4 border border-gray-300">₹2000</td>
                <td className="py-2 px-4 border border-gray-300">₹200</td>
              </tr>
              <tr className="border border-gray-300">
                <td className="py-2 px-4 border border-gray-300">User 2</td>
                <td className="py-2 px-4 border border-gray-300">₹1500</td>
                <td className="py-2 px-4 border border-gray-300">₹150</td>
              </tr>
              <tr className="border border-gray-300">
                <td className="py-2 px-4 border border-gray-300">User 3</td>
                <td className="py-2 px-4 border border-gray-300">₹5000</td>
                <td className="py-2 px-4 border border-gray-300">₹500</td>
              </tr>
              <tr className="border border-gray-300">
                <td className="py-2 px-4 border border-gray-300">User 4</td>
                <td className="py-2 px-4 border border-gray-300">₹3000</td>
                <td className="py-2 px-4 border border-gray-300">₹300</td>
              </tr>
              <tr className="border border-gray-300">
                <td className="py-2 px-4 border border-gray-300">User 5</td>
                <td className="py-2 px-4 border border-gray-300">₹1000</td>
                <td className="py-2 px-4 border border-gray-300">₹100</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="text-gray-300 text-[0.9rem] mt-4">
          In this case, your total commission for the day from these users would be <span className="font-bold">₹1250</span>.
        </p>
      </div>

      {/* Daily Calculation and Commission Credit Section */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-4">Daily Calculation and Commission Credit</h3>
        <ul className="list-disc list-inside text-gray-300 text-[0.9rem]">
          <li>Your total commission from all referred users is calculated daily starting at 12:00 AM and ending at 11:59 PM.</li>
          <li>After each day's commission is calculated, the total amount will be credited to your wallet between 12:00 AM and 2:00 AM the next day.</li>
        </ul>
      </div>

      {/* Tracking Your Commission Section */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-4">Tracking Your Commission</h3>
        <ul className="list-disc list-inside text-gray-300 text-[0.9rem]">
          <li>You can track your earnings by viewing the commission history section on your dashboard.</li>
          <li>This section will display all commissions earned, including the status of each commission credit—whether it’s pending, successful, or failed.</li>
        </ul>
      </div>

      {/* Platform Authority Section */}
      <div className=" mb-16">
        <h3 className="text-lg mb-4 font-semibold">Platform Authority (CK)</h3>
        <ul className="list-disc list-inside text-gray-300 text-[0.9rem]">
          <li>Remember, all commission activities are monitored by the CK platform.</li>
          <li>If you are found misusing or exploiting the system in any way, CK reserves the right to disable your account without any warning.</li>
        </ul>
      </div>
      <NewBottomBar/>
    </div>
  );
};

export default CommissionRatio;
