import React, { useEffect, useState } from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the data labels plugin
import BottomNav from './BottomNav';
import api from '../api/utils'; // Import the API utility
import Loader from './Loader';
import NewBottomBar from './newBottomBar';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import TopBackNav from './TopBackNav';
import { fetchAvatarImage } from '../api/avatarUtils'; // Import the utility function

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels // Register the data labels plugin
);

const UserStats = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const [avatarImage, setAvatarImage] = useState(null); 

  useEffect(() => {
    // Define the async function inside useEffect
    const loadAvatarImage = async () => {
      try {
        const avatarImageUrl = await fetchAvatarImage();
        setAvatarImage(avatarImageUrl);
      } catch (error) {
        console.error('Error fetching avatar image:', error);
      }
    };

    loadAvatarImage(); // Call the async function
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('auth_token'); // Fetch Bearer token from local storage
        const response = await api.get('/user/get-user-statistics', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setStats(response.data);
      } catch (error) {
        setError(error);
        console.error('Error fetching user statistics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div><Loader /></div>;
  if (error) return <div>Error: {error.message}</div>;

  const {
    total_bets,
    total_win_amount,
    total_loss_amount,
    total_deposit,
    total_withdrawal,
    total_bet_amount,
    total_bets_lost,
    total_bets_won,
    total_deposit_count,
    total_withdrawal_count,
    overall_net_amount
  } = stats;

  // Format values as rupees
  const formatCurrency = (amount) => `₹${amount.toLocaleString('en-IN')}`;

  // Determine text color based on value
  const getTextColor = (amount, type) => {
    if (type === 'win' && amount > 0) return 'text-green-400';
    if (type === 'loss' && amount > 0) return 'text-red-400';
    return 'text-yellow-400'; // Default color for deposits and withdrawals
  };

  return (
    <div className="flex flex-col items-center p-6 pt-0 mb-4">
    <div className="flex justify-between items-center mb-4 p-4 pb-2 w-full text-white">
      <FaArrowLeft className="font-bold text-2xl cursor-pointer" onClick={() => navigate(-1)} />
      <h1 className="text-2xl font-bold text-white">User Stats</h1>
      <div className="rounded-full bg-blue-600 h-10 w-10 flex items-center justify-center">
        <img
          src={avatarImage}
          alt="user"
          className="rounded-full"
          onClick={() => navigate('/change-avatar')}
        />
      </div>
    </div>

      {/* Statistics Grid */}
      <div className="w-full max-w-3xl grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-10">
        <div className={`p-4 rounded-lg ${getTextColor(total_win_amount, 'win')} bg-[#1a1a1a]`}>
          <h3 className="text-xl font-semibold">Total Wins</h3>
          <p className="text-2xl">{formatCurrency(total_win_amount)}</p>
        </div>
        <div className={`p-4 rounded-lg ${getTextColor(total_loss_amount, 'loss')} bg-[#1a1a1a]`}>
          <h3 className="text-xl font-semibold">Total Losses</h3>
          <p className="text-2xl">{formatCurrency(total_loss_amount)}</p>
        </div>
        <div className={`p-4 rounded-lg text-yellow-400 bg-[#1a1a1a]`}>
          <h3 className="text-xl font-semibold">Total Deposits</h3>
          <p className="text-2xl">{formatCurrency(total_deposit)}</p>
        </div>
        <div className={`p-4 rounded-lg text-yellow-400 bg-[#1a1a1a]`}>
          <h3 className="text-xl font-semibold">Total Withdrawals</h3>
          <p className="text-2xl">{formatCurrency(total_withdrawal)}</p>
        </div>
        <div className={`p-4 rounded-lg ${overall_net_amount < 0 ? 'text-red-400' : 'text-green-400'} bg-[#1a1a1a]`}>
          <h3 className="text-xl font-semibold">Overall Net Amount</h3>
          <p className="text-2xl">{formatCurrency(overall_net_amount)}</p>
        </div>
        <div className={`p-4 rounded-lg text-yellow-400 bg-[#1a1a1a]`}>
          <h3 className="text-xl font-semibold">Total Bet Amount</h3>
          <p className="text-2xl">{formatCurrency(total_bet_amount)}</p>
        </div>
        <div className={`p-4 rounded-lg text-yellow-400 bg-[#1a1a1a]`}>
          <h3 className="text-xl font-semibold">Total Bets</h3>
          <p className="text-2xl">{total_bets}</p>
        </div>
        <div className={`p-4 rounded-lg text-yellow-400 bg-[#1a1a1a]`}>
          <h3 className="text-xl font-semibold">Total Bets Won</h3>
          <p className="text-2xl">{total_bets_won}</p>
        </div>
        <div className={`p-4 rounded-lg text-yellow-400 bg-[#1a1a1a]`}>
          <h3 className="text-xl font-semibold">Total Bets Lost</h3>
          <p className="text-2xl">{total_bets_lost}</p>
        </div>
        <div className={`p-4 rounded-lg text-yellow-400 bg-[#1a1a1a]`}>
          <h3 className="text-xl font-semibold">Total Deposit Count</h3>
          <p className="text-2xl">{total_deposit_count}</p>
        </div>
        <div className={`p-4 rounded-lg text-yellow-400 bg-[#1a1a1a]`}>
          <h3 className="text-xl font-semibold">Total Withdrawal Count</h3>
          <p className="text-2xl">{total_withdrawal_count}</p>
        </div>
      </div>
      <div className='text-[#ffff]'>
        <NewBottomBar />
      </div>
    </div>
  );
};

export default UserStats;
