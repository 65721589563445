import React, { useEffect, useState } from "react";

const CountdownTimer = ({ timeRemaining }) => {
    const [seconds, setSeconds] = useState(timeRemaining);

    useEffect(() => {
        if (seconds > 0) {
            const timer = setTimeout(() => {
                setSeconds((prev) => prev - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [seconds]);

    const formatTime = () => {
        const timeString = seconds.toString().padStart(2, '0'); // Pad with 0 if needed
        return timeString.split('');
    };

    const [firstDigit, secondDigit] = formatTime();

    return (
        <div className="flex items-center justify-center space-x-2">
            <div className="bg-transparent text-[#ffffff] border border-[#ffffff] p-4 px-8 text-[6rem] font-bold rounded-md">
                {firstDigit}
            </div>
            <div className="bg-transparent text-[#ffffff] border border-[#ffffff] p-4 px-8 text-[6rem] font-bold rounded-md">
                {secondDigit}
            </div>
        </div>
    );
};

export default CountdownTimer;
