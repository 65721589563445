import React, { useState, useEffect } from 'react';
import { FaTrash, FaPlus } from 'react-icons/fa';
import BottomNav from './BottomNav';
import api from '../api/utils';

const Withdrawal = () => {
  const [cards, setCards] = useState([]);
  const [activeTab, setActiveTab] = useState('bank');
  const [amount, setAmount] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showWagerAlert, setShowWagerAlert] = useState(false);
  const [amounts, setAmounts] = useState([]);
  const [newPaymentDetails, setNewPaymentDetails] = useState({
    account_number: '',
    ifsc: '',
    bank_name: '',
    phone_number: '',
    account_holder: '',
    upi: '',
  });
  const [wagerAmount, setWagerAmount] = useState(0);

  const getToken = () => {
    return localStorage.getItem('auth_token');
  };

  useEffect(() => {
    const fetchWagerAmount = async () => {
      try {
        const token = getToken();
        const response = await api.get('/user/wager', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const wager = response.data.amount;
        setWagerAmount(wager);
        if (wager > 0) {
          setShowWagerAlert(true);
        } else {
          fetchAccountDetails(); // Fetch account details only if no wager required
        }
      } catch (error) {
        console.error('Error fetching wager amount:', error);
      }
    };

    const fetchAccountDetails = async () => {
      try {
        const token = getToken();
        const response = await api.get('/user/get-account-details', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedCards = response.data.items;
        setCards(fetchedCards);
        setAmounts(new Array(fetchedCards.length).fill('')); // Initialize amounts array
      } catch (error) {
        console.error('Error fetching account details:', error);
      }
    };
    

    fetchWagerAmount();
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleDeleteCard = (index) => {
    const accountId = cards[index].id;
    handleDeleteAccount(accountId);
  };

  const handleAmountChange = (index, value) => {
    const newAmounts = [...amounts];
    newAmounts[index] = value;
    setAmounts(newAmounts);
  };
  

  const handleDeleteAccount = async (accountId) => {
    try {
      const token = getToken();
      const response = await api.delete('/user/delete-account-details', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { id: accountId }, // Ensure `id` is sent as a query parameter
      });
  
      if (response.status === 200) {
        setCards(cards.filter(card => card.id !== accountId));
      }
    } catch (error) {
      console.error('Error deleting account details:', error.response ? error.response.data : error.message);
    }
  };
  

  const handleWithdrawal = async (amount, accountId) => {
    try {
      const token = getToken(); // Fetch the token securely
      const response = await api.post('/user/withdraw', {
        amount: parseFloat(amount),
        account_id: accountId,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        alert('Withdrawal successful');
        // Optionally display additional information (e.g., response.data)
      } else {
        alert('Failed to withdraw funds. Please try again.');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert('Session expired, plkease log in again.');
        // Optionally, redirect to login page
      } else {
        console.error('Error withdrawing funds:', error);
        // minimum fund is 100
        alert('Failed to withdraw funds. Please try again later.');
      }
    }
  };
  

  const handleSubmit = (index) => {
    if (wagerAmount > 0) {
      setShowWagerAlert(true);
    } else {
      const accountId = cards[index].id;
      handleWithdrawal(amounts[index], accountId);
    }
  };
  

  const handleModalSubmit = async () => {
    try {
      const token = getToken();
      // // console.log('Submitting data:', newPaymentDetails);
      const response = await api.post('/user/add-account-details', newPaymentDetails, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // // // console.log(response);

      if (response.status === 200) {
        setCards([...cards, newPaymentDetails]);
        setShowModal(false);
        setNewPaymentDetails({
          account_number: '',
          ifsc: '',
          bank_name: '',
          phone_number: '',
          account_holder: '',
          upi: '',
        });
      }
    } catch (error) {
      console.error('Error adding payment details:', error.response || error.message);
    }
    // Reload the current page
    window.location.reload();

  };

  // // // console.log("newPaymentDetails", newPaymentDetails);

  return (
    <div className="flex flex-col items-center p-6">
      <div className="w-full max-w-screen-lg rounded-lg shadow-lg">
        <h1 className="text-2xl text-white font-semibold mb-8">Withdrawal Zone</h1>
        <div className="flex justify-end items-center mb-1 mt-3">
          <button
            onClick={() => setShowModal(true)}
            className="flex items-center text-[0.7rem] bg-blue-600 text-white px-4 py-2 rounded-md transition-colors duration-300 ease-in-out mb-2"
          >
            <FaPlus className="mr-1" />
            Add Payment Details
          </button>
        </div>

        {cards.map((card, index) => (
  <div
    key={card.id || index}
    className="bg-[#282c2c] text-white p-6 rounded-lg shadow-md mb-6 relative"
  >
    <button
      onClick={() => handleDeleteCard(index)}
      className="absolute top-2 right-2 text-gray-400 hover:text-red-600 transition-colors duration-300 ease-in-out"
    >
      <FaTrash />
    </button>

    {/* Conditionally render UPI details if available, otherwise bank details */}
    {card.upi_id ? (
      <div className="mb-4">
        <div className="text-lg font-semibold">UPI ID: {card.upi_id}</div>
      </div>
    ) : (
      <div className="mb-4">
        <div className="text-lg font-semibold">Account Number: {card.account_number}</div>
        <div className="text-sm">IFSC Code: {card.ifsc}</div>
        <div className="text-sm">Bank Name: {card.bank_name}</div>
      </div>
    )}

    <div className="mb-4">
      <input
        type="text"
        value={amounts[index] || ''}
        onChange={(e) => handleAmountChange(index, e.target.value)}
        placeholder="Amount"
        className="w-full p-1 pl-3 rounded-md text-black"
      />
    </div>
    <button
      onClick={() => handleSubmit(index)}
      className="bg-green-600 text-white p-1 px-3 rounded-md flex items-center justify-center transition-colors duration-300 ease-in-out"
    >
      Submit
    </button>
  </div>
        ))}

      </div>

      {/* Wager Alert Modal */}
      {showWagerAlert && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center m-2">
          <div className="bg-gray-800 p-6 rounded-lg w-80">
            <h2 className="text-lg font-bold mb-4 text-white">Wager Required</h2>
            <p className="text-white">You need to wager {wagerAmount} before withdrawing.</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowWagerAlert(false)}
                className="bg-red-600 text-white p-2 rounded-md transition-colors duration-300 ease-in-out"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add Payment Details Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center m-2">
          <div className="bg-gray-800 p-6 rounded-lg w-96">
            <h2 className="text-xl font-bold mb-4 text-white">Add Payment Details</h2>

            {/* Tabs */}
            <div className="flex mb-4">
              <button
                onClick={() => handleTabClick('bank')}
                className={`flex-1 py-2 text-center ${
                  activeTab === 'bank' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-400'
                }`}
              >
                Bank
              </button>
              <button
                onClick={() => handleTabClick('upi')}
                className={`flex-1 py-2 text-center ${
                  activeTab === 'upi' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-400'
                }`}
              >
                UPI
              </button>
            </div>

            {/* Bank Tab Content */}
            {activeTab === 'bank' && (
              <>
                <div className="mb-4">
                  <input
                    type="text"
                    value={newPaymentDetails.account_number}
                    onChange={(e) => setNewPaymentDetails({ ...newPaymentDetails, account_number: e.target.value })}
                    placeholder="Account Number"
                    className="w-full p-2 border border-gray-300 rounded-md text-white placeholder:text-white bg-gray-800"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    value={newPaymentDetails.ifsc}
                    onChange={(e) => setNewPaymentDetails({ ...newPaymentDetails, ifsc: e.target.value })}
                    placeholder="IFSC Code"
                    className="w-full p-2 border border-gray-300 rounded-md text-white placeholder:text-white bg-gray-800"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    value={newPaymentDetails.bank_name}
                    onChange={(e) => setNewPaymentDetails({ ...newPaymentDetails, bank_name: e.target.value })}
                    placeholder="Bank Name"
                    className="w-full p-2 border border-gray-300 rounded-md text-white placeholder:text-white bg-gray-800"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    value={newPaymentDetails.phone_number}
                    onChange={(e) => setNewPaymentDetails({ ...newPaymentDetails, phone_number: e.target.value })}
                    placeholder="Phone Number"
                    className="w-full p-2 border border-gray-300 rounded-md text-white placeholder:text-white bg-gray-800"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    value={newPaymentDetails.account_holder}
                    onChange={(e) => setNewPaymentDetails({ ...newPaymentDetails, account_holder: e.target.value })}
                    placeholder="Account Holder Name"
                    className="w-full p-2 border border-gray-300 rounded-md text-white placeholder:text-white bg-gray-800"
                  />
                </div>
              </>
            )}

            {/* UPI Tab Content */}
            {activeTab === 'upi' && (
              <>
                <div className="mb-4">
                  <input
                    type="text"
                    value={newPaymentDetails.upi}
                    onChange={(e) => setNewPaymentDetails({ ...newPaymentDetails, upi: e.target.value })}
                    placeholder="UPI ID"
                    className="w-full p-2 border border-gray-300 rounded-md text-white placeholder:text-white bg-gray-800"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    value={newPaymentDetails.account_holder}
                    onChange={(e) => setNewPaymentDetails({ ...newPaymentDetails, account_holder: e.target.value })}
                    placeholder="Account Holder Name"
                    className="w-full p-2 border border-gray-300 rounded-md text-white placeholder:text-white bg-gray-800"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    value={newPaymentDetails.phone_number}
                    onChange={(e) => setNewPaymentDetails({ ...newPaymentDetails, phone_number: e.target.value })}
                    placeholder="Phone Number"
                    className="w-full p-2 border border-gray-300 rounded-md text-white placeholder:text-white bg-gray-800"
                  />
                </div>
              </>
            )}

            {/* Action Buttons */}
            <div className="flex justify-end">
              <button
                onClick={handleModalSubmit}
                className="bg-green-600 text-white p-2 rounded-md transition-colors duration-300 ease-in-out"
              >
                Submit
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="ml-2 bg-red-600 text-white p-2 rounded-md transition-colors duration-300 ease-in-out"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <BottomNav />
    </div>
  );
};

export default Withdrawal;
