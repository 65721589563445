import React, { useState, useRef, useEffect } from 'react';
import Logo from './logo.png';
import { FaQuestionCircle, FaVolumeUp, FaVolumeMute, FaTimes } from 'react-icons/fa';
// import gameStartSound from './bheegi_bhaagi.mp3'; // Ensure the path is correct
import { useNavigate } from 'react-router-dom';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { MdCircleNotifications } from 'react-icons/md';

const TopNav = ({ setZIndexControl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(false); // Default to sound off until interaction
  const audioRef = useRef(null);
  const navigate = useNavigate();

  // Open and close modal handlers
  const openModal = () => {
    setZIndexControl(true); // Toggle z-index
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setZIndexControl(false);
    setIsModalOpen(false);
  };

  // Close modal when clicking outside the modal content
  const handleOutsideClick = (e) => {
    if (e.target.id === 'modalOverlay') {
      closeModal();
    }
  };

  return (
    <>

      {/* Top Navigation Bar */}
      <div className='fixed top-0 right-0 bg-[#1a1a1a] w-full z-50'>
        <div className="flex justify-between items-center text-white p-4 pt-0.5 pb-0.5">    
          {/* Help Button */}
          <button 
            className="text-yellow-500 text-3xl" 
            onClick={openModal}
            aria-label="How to Play"
          >
            <HiQuestionMarkCircle />
          </button>

          {/* Logo */}
          <img src={Logo} alt="logo" className="h-11" />

          {/* Sound Toggle Button */}
          {/* <button 
            className="text-yellow-500 text-lg" 
            onClick={toggleSound}
            aria-label="Toggle Sound"
          >
            {isSoundOn ? <FaVolumeUp /> : <FaVolumeMute />}
          </button> */}
          <button 
            className="text-yellow-500 text-3xl"
            aria-label="Toggle Sound"
            onClick={() => navigate('/notifications')} 
          >
            <MdCircleNotifications />
          </button>
        </div>
      </div>

      {/* Modal Overlay */}
      {isModalOpen && (
  <div 
    id="modalOverlay" 
    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    onClick={handleOutsideClick}
  >
    {/* Modal Content */}
    <div className="bg-[#1a1a1a] text-white rounded-lg w-11/12 md:w-1/3 p-6 relative">
      {/* Close Button */}
      <button 
        className="absolute top-3 right-3 text-white hover:text-gray-800 transition-colors duration-300" 
        onClick={closeModal}
        aria-label="Close Modal"
      >
        <FaTimes size={20} />
      </button>

      {/* Modal Title */}
      <h2 className="text-2xl font-bold mb-4 text-center text-yellow-400">How to Play Chidiya Kabutar</h2>

      {/* Modal Body */}
      <div className="text-white">
        <p>
          Playing <span className="text-green-400 font-semibold">Chidiya Kabutar</span> is easy and exciting! Here's how it works:
        </p>

        <ul className="list-disc list-inside mt-2 space-y-2">
          <li>
            <span className="text-blue-400 font-semibold">Place Your Bet:</span> 
            You’ll see <span className="text-red-400 font-semibold">12 tiles</span> on the game screen. Choose one or more tiles to place your bets on.
          </li>
          <li>
            <span className="text-blue-400 font-semibold">Betting Time:</span> 
            You have <span className="text-orange-400 font-semibold">45 seconds</span> to place your bets. The bet will close in the last <span className="text-red-400 font-semibold">5 seconds</span>, so be quick to finalize your choice!
          </li>
          <li>
            <span className="text-blue-400 font-semibold">Winning:</span> 
            If the tile you bet on wins, you'll receive <span className="text-yellow-400 font-semibold">10x</span> your bet amount!
          </li>
          <li>
            <span className="text-blue-400 font-semibold">Check Outcome:</span> 
            Once the game round is complete, you can check the outcome of your bet in the <span className="text-purple-400 font-semibold">History</span> section on the same page to see if you've won.
          </li>
        </ul>

        <p className="mt-2">
          It’s that simple! Place your bets, watch the tiles, and enjoy the thrill of big wins.
        </p>
      </div>
    </div>
  </div>
)}

    </>
  );
};

export default TopNav;
