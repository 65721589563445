import React, { useState, useEffect } from 'react';
import { FaArrowDown, FaArrowUp, FaHistory, FaRegUserCircle, FaMoneyBillWave,FaWallet, FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa';
// import { , FaRegCreditCard, FaUniversity, FaUserFriends, , FaPowerOff } from 'react-icons/fa';
// import { } from 'react-icons/ai';
import {  AiOutlineSetting, AiOutlineRise, AiOutlineClockCircle, AiOutlineNotification } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import api from '../api/utils'; // assuming you have the api/utils module for API requests
import NewBottomBar from './newBottomBar';
import { fetchAvatarImage } from '../api/avatarUtils'; // Import the utility function
import {CiInboxIn, CiInboxOut, CiSaveDown1, CiSaveUp1} from 'react-icons/ci'
import {HiOutlineSave} from 'react-icons/hi'
import {PiHandDepositFill,PiHandWithdrawFill} from 'react-icons/pi'

const UpdatedWallet = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [avatarImage, setAvatarImage] = useState(null); 

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const avatarImageUrl = await fetchAvatarImage();
      setAvatarImage(avatarImageUrl);
      try {
        const token = localStorage.getItem('auth_token'); // Fetch Bearer token from local storage
        const response = await api.get('/user/me', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUserData(response.data.user);
      } catch (error) {
        setError(error);
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    // Fetch user data initially
    fetchUserData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="min-h-screen text-white mont-font">
      {/* Header */}
      <div className="flex items-center justify-between p-4 pt-0 pl-0 pr-0 pb-0">
        <div className="flex items-center space-x-2 bg-[#282c2c] bg-opacity-50 p-4 rounded-full">
          <img src={avatarImage} alt='fetchAvatarImage' className='w-8 h-8 rounded-full' 
                onClick={() => {navigate('/change-avatar')}}/>
          <span className="text-lg">Hi, {userData?.name || 'User'}!</span>
        </div>
        <div className="bg-black bg-opacity-50 p-4 rounded-full" onClick={() => navigate('/payout-settings')}>
            
          <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full pb-4'>
          <AiOutlineSetting className="text-2xl" />
            </div>
        </div>
      </div>

      {/* Total Currency Balance */}
      <div className="px-4 py-4">
        <p className="text-sm text-gray-400">Total Currency Balance</p>
        <div className="flex items-center justify-between space-x-2">
          <p className="text-4xl font-bold">₹ {userData?.wallet?.balance || '0.00'}</p>
          <div className="bg-gray-100 p-4 rounded-full bg-opacity-10">
            <AiOutlineRise className="text-green-500 text-3xl" />
          </div>
        </div>
      </div>

      {/* Deposit and Withdraw Section */}
      <div className="grid grid-cols-2 gap-4 px-4 mx-2 font-bold rounded-lg mt-6 bg-[#1a1a1a] p-4">
        <div className="bg-[#282c2c] p-4 rounded-lg flex flex-col items-center justify-center" onClick={() => navigate('/deposit-zone')}>
          
        <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
          <PiHandDepositFill className="text-2xl" />
            </div>
          <p className="text-sm">Deposit</p>
        </div>
        <div className="bg-[#282c2c] p-4 rounded-lg flex flex-col items-center justify-center" onClick={() => navigate('/withdraw-zone')}>
          
        <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
          <PiHandWithdrawFill className="text-2xl" />
            </div>
          <p className="text-sm">Withdraw</p>
        </div>
      </div>

      {/* Manage Wallet Section */}
    <div className="px-4 mt-3 mb-24 bg-[#1a1a1a] m-2 p-2 rounded-lg" onClick={() => navigate('/wallet')}>
      <p className="text-lg py-2 font-semibold">Manage your wallet</p>
      <div className="grid grid-cols-3 gap-3 mt-4 font-bold">
        <div
          className="p-5 px-[1] bg-[#282c2c] text-center rounded-lg flex flex-col items-center justify-center py-3"
          onClick={(e) => {
            e.stopPropagation();
            navigate('/deposit-history');
          }}
        >
          <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
            <FaArrowCircleDown className="text-2xl font-bold" />
          </div>
          <p className="text-sm">Deposit History</p>
        </div>
        <div
          className="p-5 px-[1] bg-[#282c2c] text-center rounded-lg flex flex-col items-center justify-center py-0"
          onClick={(e) => {
            e.stopPropagation();
            navigate('/withdraw-history');
          }}
        >
          <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
            <FaArrowCircleUp className="text-2xl" />
          </div>
          <p className="text-sm">Withdraw History</p>
        </div>
        <div
          className="p-5 px-[1] bg-[#282c2c] text-center rounded-lg flex flex-col items-center justify-center py-0"
          onClick={(e) => {
            e.stopPropagation();
            navigate('/commission-history');
          }}
        >
          <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
            <HiOutlineSave className="text-2xl" />
          </div>
          <p className="text-sm">Commission History</p>
        </div>
        <div
          className="p-5 px-[1] bg-[#282c2c] text-center rounded-lg flex flex-col items-center justify-center py-2"
          onClick={(e) => {
            e.stopPropagation();
            navigate('/bet-history');
          }}
        >
          <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
            <FaHistory className="text-2xl" />
          </div>
          <p className="text-sm">Win/Loss History</p>
        </div>
      </div>
    </div>

      
      <NewBottomBar/>
    </div>
  );
};

export default UpdatedWallet;
