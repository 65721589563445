import React, { useState, useEffect } from 'react';
import { FaUniversity, FaArrowLeft, FaCopy } from 'react-icons/fa';
import { AiOutlineCopy, AiOutlineInfoCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import NewBottomBar from './newBottomBar';
import utr_num from './images/utr_num.jpg'; // Path to the UTR image
import TopBackNav from './TopBackNav';
import api from '../api/utils';

const baseURL = process.env.REACT_APP_API_BASE_URL; // Base URL for images

const DepositZone = () => {
  const [selectedGatewayIndex, setSelectedGatewayIndex] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [amount, setAmount] = useState('');
  const [utr, setUtr] = useState('');
  const [showUTRImage, setShowUTRImage] = useState(false); // State for hover effect
  const [cooldownTime, setCooldownTime] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (cooldownTime !== null) {
      const interval = setInterval(() => {
        const newCooldownTime = cooldownTime - 1000; // Decrease cooldown time by 1 second
        if (newCooldownTime <= 0) {
          clearInterval(interval);
          setCooldownTime(null); // Clear the cooldown when it reaches 0
          localStorage.removeItem('last_deposit_time'); // Optionally clear last deposit time
        } else {
          setCooldownTime(newCooldownTime);
        }
      }, 1000);
  
      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [cooldownTime]);
  

  useEffect(() => {
    // Fetch the payment methods on component load
    const fetchPaymentMethods = async () => {
      try {
        const authToken = localStorage.getItem('auth_token');
        const response = await api.get('/user/payment-methods', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const data = response.data; // Assuming response.data contains the data
        setPaymentMethods(data.payment_methods || []);
        setSelectedGatewayIndex(0); // Set default selected gateway index
      } catch (error) {
        console.error('Error fetching payment methods:', error);
      }
    };
  
    // Check cooldown from localStorage
    const lastDepositTime = localStorage.getItem('last_deposit_time');
    if (lastDepositTime) {
      const cooldownRemaining = 100000 - (Date.now() - lastDepositTime);
      if (cooldownRemaining > 0) {
        setCooldownTime(cooldownRemaining);
      }
    }

    fetchPaymentMethods();
  }, []);

  const handleGatewayChange = (index) => {
    setSelectedGatewayIndex(index);
  };

  const handleDepositSubmit = async () => {
    const lastDepositTime = localStorage.getItem('last_deposit_time');
    const currentTime = Date.now();

    if (lastDepositTime && currentTime - lastDepositTime < 100000) {
      const remainingTime = 100 - Math.floor((currentTime - lastDepositTime) / 1000);
      alert(`Please wait ${remainingTime} seconds before making another deposit.`);
      return;
    }

    if (!amount || !utr || selectedGatewayIndex === null) {
      alert('Please fill all fields.');
      return;
    }

    const selectedGateway = paymentMethods[selectedGatewayIndex];

    try {
      const response = await fetch(`${baseURL}/user/deposit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
        body: JSON.stringify({
          amount: parseInt(amount, 10),
          utr,
          payment_id: selectedGateway.id,
        }),
      });
      const result = await response.json();
      alert(result.message || 'Deposit submitted successfully');

      // Save current time as last deposit time in localStorage
      localStorage.setItem('last_deposit_time', Date.now());

      // Clear the form fields only
      setAmount(''); // Clear the amount input
      setUtr(''); // Clear the UTR input
      window.location.reload();

    } catch (error) {
      console.error('Error submitting deposit:', error);
    }
  };

  const gatewayNames = ['EasyPay', 'PayUBiz', 'Paytm', 'Razorpay', 'instamojo', 'Paypal', 'PhonePe', 'EaseBuzz'];

  const getGatewayName = (index) => {
    return gatewayNames[index] || '';
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => alert('UPI ID copied to clipboard!'))
      .catch((error) => console.error('Failed to copy UPI ID:', error));
  };

  return (
    <div className="min-h-screen bg-black text-white p-6 pt-1 relative">
      {showUTRImage && (
        <div className="z-50 absolute top-[20%] left-1/3 transform -translate-x-1/2 mt-2 w-90 p-2 bg-white border border-gray-300 rounded-lg shadow-lg">
          <img
            src={utr_num}
            alt="UTR Example"
            className="w-99 h-99 h-auto object-cover"
          />
        </div>
      )}
      <TopBackNav heading='Deposit Zone' />

      <div className="flex flex-col items-center justify-center">
        <div className='flex justify-start text-left'>
          <p className="text-gray-400 text-sm my-6 mb-0 text-left">Select Payment Gateway</p>
        </div>
        <div className="flex space-x-4 bg-[#1a1a1a] p-4 px-6 rounded-lg w-full justify-between">
          {paymentMethods.map((gateway, index) => (
            <button
              key={gateway.id}
              className={`flex flex-col items-center justify-center bg-[#282c2c] rounded-lg w-[45%] h-16 p-3 ${selectedGatewayIndex === index ? 'ring-4 ring-blue-500' : ''}`}
              onClick={() => handleGatewayChange(index)}
            >
              <FaUniversity className="text-white text-2xl" />
              <p className="text-xs mt-2">{getGatewayName(index)}</p>
            </button>
          ))}
        </div>

        {selectedGatewayIndex !== null && (
          <>
            <div className="bg-blue-700 w-64 h-64 flex flex-col items-center justify-center">
              <img
                src={`${baseURL}/images/payment/${paymentMethods[selectedGatewayIndex].qr_code}`}
                alt="QR Code"
                className="w-full h-full object-cover"
              />
            </div>
            <p className="text-white mt-2 flex items-center font-bold bg-[#282c2c] p-4 py-3 mb-2 rounded-lg">
              UPI ID: {paymentMethods[selectedGatewayIndex].upi_id || 'UPI ID not available'}
              {paymentMethods[selectedGatewayIndex].upi_id && (
                <FaCopy 
                  className="ml-2 cursor-pointer" 
                  onClick={() => copyToClipboard(paymentMethods[selectedGatewayIndex].upi_id)} 
                />
              )}
            </p>
            <p className='text-[0.7rem] font-bold'>
              (UPI ID can be used to make payments above 2000)
            </p>
          </>
        )}

        {selectedGatewayIndex !== null && (
          <div className="bg-[#1a1a1a] w-full max-w-md mt-6 p-4 rounded-lg text-center px-5">
            <h2 className="text-white font-semibold text-sm">Account</h2>
            <p className="text-gray-400 text-sm pb-2 text-center">
              Minimum Deposit: ₹{paymentMethods[selectedGatewayIndex].min_amount} <br/> Maximum Deposit: ₹{paymentMethods[selectedGatewayIndex].max_amount}
            </p>
            <div className="flex items-center bg-white rounded-md p-2 mt-2">
              <input
                type="number"
                placeholder="Enter ₹ Amount"
                className="flex-grow bg-transparent text-black outline-none placeholder-gray-500"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="flex items-center bg-white rounded-md p-2 mt-2">
              <input
                type="number" // Change to 'number' type for UTR input
                placeholder="Enter UTR Number"
                className="flex-grow bg-transparent text-black outline-none placeholder-gray-500"
                value={utr}
                onChange={(e) => setUtr(e.target.value)}
              />
              <AiOutlineInfoCircle
                className="text-black ml-2 cursor-pointer"
                onMouseEnter={() => setShowUTRImage(true)}
                onMouseLeave={() => setShowUTRImage(false)}
              />
            </div>
            <button
            className="bg-green-500 w-full text-white py-2 rounded-md mt-3 hover:bg-green-600"
            onClick={handleDepositSubmit}
            disabled={cooldownTime !== null}
          >
            {cooldownTime !== null ? `Wait ${Math.ceil(cooldownTime / 1000)} seconds` : 'Submit'}
          </button>

          </div>
        )}
      </div>
      <div className="bg-[#1a1a1a] mt-4 p-4 rounded-lg">
        {/* Deposit Guidelines */}
        <div className="mb-20">
          <h4 className="font-semibold text-bold text-underline text-center text-lg">Deposit Guidelines</h4>
          <ul className="list-disc list-inside text-gray-300 text-[1rem]">
            <li>Deposits will be credited<strong> within 10 seconds to 10 minutes</strong>; please be patient during this time.</li>
            <li>Ensure the amount entered <strong>matches</strong> the exact payment amount; mismatched amounts will not be credited, and CK is not responsible for any loss.</li>
            <li>Enter the <strong>matches</strong> correct UTR (Unique Transaction Reference) number; incorrect UTRs may delay or block your payment.</li>
            <li>Only confirmed transactions will be added to your balance; if there are issues, wait 10 minutes before contacting support.</li>
            <li><strong>Fraudulent</strong> payments, <strong>spam</strong> accounts, or <strong>suspicious activity</strong> will result in blocked payments and potential account freezing.</li>
            <li>CK reserves the right to decline payments if deemed illegal or suspicious, and can block or disable accounts in such cases.</li>
          </ul>
        </div>
      </div>
      <NewBottomBar />
    </div>
  );
};

export default DepositZone;
