import React, { useEffect, useState } from 'react'; // Import useState
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
// import frb from './images/first_recharge_bonus.jpg';
import frb from './images/frb.webp'
import ck from './images/ck_agent.jpg';
import jckot from './images/jckot.jpg';
import NewBottomBar from './newBottomBar';
import TopBackNav from './TopBackNav';
import { fetchAvatarImage } from '../api/avatarUtils'; // Import the utility function

const Activity = () => {
  const navigate = useNavigate();
  const [avatarImage, setAvatarImage] = useState(null); 

  useEffect(() => {
    // Define the async function inside useEffect
    const loadAvatarImage = async () => {
      try {
        const avatarImageUrl = await fetchAvatarImage();
        setAvatarImage(avatarImageUrl);
      } catch (error) {
        console.error('Error fetching avatar image:', error);
      }
    };

    loadAvatarImage(); // Call the async function
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="min-h-screen bg-black text-white mont-font mb-24 flex flex-col items-center" style={{ maxWidth: '400px', margin: '0 auto' }}>
      {/* Header */}
      <div className="flex justify-between items-center mb-4 p-4 pb-2 w-full">
        <FaArrowLeft className="font-bold text-2xl cursor-pointer" onClick={() => navigate(-1)} />
        <h1 className="text-2xl font-bold">Activity Bar</h1>
        <div className="rounded-full bg-blue-600 h-10 w-10 flex items-center justify-center">
          <img
            src={avatarImage}
            alt="user"
            className="rounded-full"
          />
        </div>
      </div>
      {/* <TopBackNav heading='Activity Bar' /> */}

      {/* Content */}
      <div className="w-full">
        <div className="m-4" onClick={() => navigate('/bonus')}>
          <img src={frb} alt="frb" className="rounded-[1.5rem] w-full" />
        </div>

        <div className="m-4" onClick={() => navigate('/agent')}>
          <img src={ck} alt="ck" className="rounded-[1.5rem] w-full" />
        </div>

        <div className="m-4">
          <a href="https://t.me/ChidiyaKabutarOfficial" target="_blank" rel="noopener noreferrer">
            <img src={jckot} alt="jckot" className="rounded-[1.5rem] w-full" />
          </a>
        </div>
      </div>

      {/* Bottom Navigation */}
      <NewBottomBar />
    </div>
  );
};

export default Activity;
