import React from 'react'

const Sample = () => {
  return (
    <div className='bg-gray-800 bg-opacity-75'>
        Sample
    </div>
  )
}

export default Sample