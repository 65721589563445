import React from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
  return (
    <div className="bg-black text-white min-h-screen p-8 px-2">
      <div className="max-w-4xl mx-auto bg-[#1a1a1a] p-6 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-8">Chidiya Kabutar - T&C</h1>
        <p className="mb-6">
          Welcome to <strong>Chidiya Kabutar</strong>. By accessing and using our platform, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully, as they govern your participation in our games and the use of our services. If you do not agree with these terms, please refrain from using the platform.
        </p>

        <h2 className="text-2xl font-semibold mb-4">1. Age and Eligibility</h2>
        <ul className="list-disc pl-5 mb-6">
          <li>You must be at least <strong>18 years of age</strong> to participate in any games on the Chidiya Kabutar platform.</li>
          <li>By creating an account, you confirm that you meet the age requirement and are legally allowed to engage in such activities according to your local laws.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">2. Risk of Loss</h2>
        <ul className="list-disc pl-5 mb-6">
          <li><strong>Chidiya Kabutar</strong> is a betting platform, and by participating, you understand that all games involve a risk of <strong>monetary loss</strong>. There is no guarantee of profit or success, and any amount wagered is subject to loss.</li>
          <li><strong>Chidiya Kabutar</strong> does not offer any assurances or warranties regarding the outcome of any bets placed. You acknowledge that participation in betting is your own decision, and you are responsible for any financial loss incurred during gameplay.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">3. No Refunds</h2>
        <ul className="list-disc pl-5 mb-6">
          <li>All bets placed and funds deposited into your account are <strong>non-refundable</strong>.</li>
          <li><strong>Chidiya Kabutar</strong> does not offer refunds or compensation for any money lost during gameplay. It is your sole responsibility to manage your wagers, and there are no provisions for reversing or refunding transactions once placed.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">4. Account Responsibility</h2>
        <ul className="list-disc pl-5 mb-6">
          <li>You are solely responsible for maintaining the confidentiality of your account login credentials, including your username and password.</li>
          <li>Any activity that occurs under your account is your responsibility, and <strong>Chidiya Kabutar</strong> is not liable for any unauthorized use or access to your account.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">5. Spam, Fraud, and Illegal Activities</h2>
        <ul className="list-disc pl-5 mb-6">
          <li><strong>Chidiya Kabutar</strong> takes spam, fraud, and illegal activities seriously. If any user is found to be using <strong>automated bots, engaging in illegal transactions, or spamming</strong> the platform, their account may be subject to immediate action.</li>
          <li><strong>Chidiya Kabutar</strong> reserves the right to <strong>block, freeze, or terminate</strong> any account found to be involved in such activities without notice or compensation.</li>
          <li>Any funds in accounts involved in fraudulent or illegal activity may be withheld or forfeited at <strong>Chidiya Kabutar's</strong> discretion.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">6. System Errors and Interruptions</h2>
        <ul className="list-disc pl-5 mb-6">
          <li>In the event of system errors, interruptions, or technical issues that affect your gameplay, <strong>Chidiya Kabutar</strong> will make every reasonable effort to resolve these issues.</li>
          <li>However, <strong>Chidiya Kabutar</strong> is not responsible for any losses incurred due to system malfunctions, server downtime, or technical failures during gameplay.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">7. No Liability for Losses</h2>
        <ul className="list-disc pl-5 mb-6">
          <li><strong>Chidiya Kabutar</strong> is not responsible for any financial losses or damages resulting from your use of the platform.</li>
          <li>Participation in games is at your own risk, and <strong>Chidiya Kabutar</strong> cannot be held liable for any losses, including but not limited to loss of funds, winnings, or data.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">8. Fair Play and Anti-Fraud Policy</h2>
        <ul className="list-disc pl-5 mb-6">
          <li>All users are expected to engage in fair play while using the platform. Any attempts to exploit, hack, or manipulate the platform will result in immediate account suspension and possible legal action.</li>
          <li><strong>Chidiya Kabutar</strong> reserves the right to monitor all activities and investigate suspicious behaviors. If fraudulent activities or cheating are detected, <strong>Chidiya Kabutar</strong> reserves the right to take action, including but not limited to withholding funds, freezing the account, and legal prosecution.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">9. Right to Terminate or Modify Services</h2>
        <ul className="list-disc pl-5 mb-6">
          <li><strong>Chidiya Kabutar</strong> reserves the right to terminate or modify its services, games, or these terms and conditions at any time, with or without notice.</li>
          <li>It is your responsibility to regularly review these terms for any changes. Your continued use of the platform after such modifications will constitute acceptance of the new terms.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">10. Responsible Gaming</h2>
        <ul className="list-disc pl-5 mb-6">
          <li><strong>Chidiya Kabutar</strong> encourages responsible gaming and urges all players to set limits on their gaming activity and spending.</li>
          <li>If you believe you are experiencing problems with gambling, please consider seeking help from a professional and use the available tools on our platform to manage your gaming habits.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">11. Privacy Policy</h2>
        <ul className="list-disc pl-5 mb-6">
          <li>We value your privacy and are committed to protecting your personal information. For details on how your data is collected, used, and protected, please review our <Link to="/privacypolicy" className="text-blue-400 hover:underline">Privacy Policy</Link>.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">12. User Agreement</h2>
        <ul className="list-disc pl-5 mb-6">
          <li>By creating an account and participating in games on <strong>Chidiya Kabutar</strong>, you acknowledge that you have read, understood, and agree to all terms and conditions outlined above.</li>
          <li>Any violation of these terms may result in the suspension, termination, or banning of your account, and <strong>Chidiya Kabutar</strong> reserves the right to pursue further action if necessary.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">13. Legal Compliance</h2>
        <ul className="list-disc pl-5 mb-6">
          <li>It is your responsibility to ensure that your use of the platform complies with all applicable laws and regulations in your jurisdiction.</li>
          <li><strong>Chidiya Kabutar</strong> does not guarantee that its services are legal in all locations, and it is up to the user to confirm that they are legally permitted to participate.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">14. CK's Right to Modify or Block Accounts</h2>
        <ul className="list-disc pl-5 mb-6">
          <li><strong>Chidiya Kabutar</strong> reserves the right to <strong>modify, freeze, or permanently disable</strong> any account if suspicious or fraudulent activity is detected, or if any violation of the terms and conditions is observed.</li>
          <li><strong>Chidiya Kabutar</strong> holds the authority to decline any transaction or account request that is considered illegal, spam-related, or against the platform’s policies.</li>
        </ul>

        <p className="mt-8">
          By using <strong>Chidiya Kabutar</strong>, you agree to these terms and conditions. If you have any questions or concerns, please reach out to our support team for clarification.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
