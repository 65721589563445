import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from '../api/utils';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import image1 from './images/ball.png';
import image2 from './images/balti.png';
import image3 from './images/diya.png';
import image4 from './images/cow.png';
import image5 from './images/kabutar.png';
import image6 from './images/kite.png';
import image7 from './images/lattu.png';
import image8 from './images/rabbit.png';
import image9 from './images/rose.png';
import image10 from './images/sun.jpg';
import image11 from './images/titli.png';
import image12 from './images/umbrella.png';
import NewBottomBar from './newBottomBar';

const PrevHistory = ({ showNav = true, showBottom = true }) => {
  const [yourHistoryData, setYourHistoryData] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);
  const rowsPerPage = 7;
  const navigate = useNavigate();

  const items = useMemo(() => [
    { id: 1, label: 'छतरी', image: image12 },
    { id: 2, label: 'बॉल', image: image1 },
    { id: 3, label: 'सूरज', image: image10 },
    { id: 4, label: 'दिया', image: image3 },
    { id: 5, label: 'गाय', image: image4 },
    { id: 6, label: 'बाल्टी', image: image2 },
    { id: 7, label: 'पतंग', image: image6 },
    { id: 8, label: 'लट्टू', image: image7 },
    { id: 9, label: 'गुलाब', image: image9 },
    { id: 10, label: 'तितली', image: image11 },
    { id: 11, label: 'कबूतर', image: image5 },
    { id: 12, label: 'खरगोश', image: image8 }
  ], []);

  const getWinnerDetails = (winnerId) => {
    const winner = items.find(item => item.id === winnerId);
    return winner ? { name: winner.label, image: winner.image } : { name: 'Unknown', image: null };
  }

  const fetchYourHistory = useCallback(async () => {
    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      setError('Authentication token not found. Please login.');
      return;
    }

    try {
      const response = await api.get('/user/get-bet-history', {
        params: { page: currentPage, page_size: rowsPerPage },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response && response.data) {
        const totalCount = response.data.total_count || 0;
        const pages = Math.ceil(totalCount / rowsPerPage);

        const yourHistoryData = response.data.items.map(item => ({
          id: item.round,
          created_at: new Date(item.created_at * 1000).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }),
          bets: item.bets,
          netOutcome: item.net_overall,
          winItem: items.find(i => i.id === item.win_item_id),
          net_overall_final: item.net_overall,
          winner_final: item.winner,
          win_amount_final: item.win_amount,
          lose_amount_final: item.loose_amount
        }));

        setYourHistoryData(yourHistoryData);
        setTotalPages(pages);
      } else {
        setError('Invalid response data.');
      }
    } catch (error) {
      console.error('Error fetching history:', error);
      setError("No results found");
    }
  }, [currentPage, items]);

  useEffect(() => {
    fetchYourHistory();
  }, [fetchYourHistory]);

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <div className="bg-black text-white p-4">
      <div className='flex justify-start items-center pb-3'>
        <FaArrowLeft className='font-bold pr-2 text-2xl' onClick={() => navigate(-1)} />
        <h1 className="text-2xl font-bold">Your History</h1>
      </div>
      <div className="bg-[#1a1a1a] rounded-b-lg p-4 mb-4">
        {error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <>
            {yourHistoryData.length === 0 ? (
              <p className="text-yellow-500 text-center">You have not played any bets yet</p>
            ) : (
              <table className="w-full table-auto">
                <thead className="text-yellow-500 border-b border-gray-500">
                  <tr>
                    <th className="py-2 text-left">Session ID</th>
                    <th className="py-2 text-center">Amount</th>
                    <th className="py-2 text-right">Bet Outcome</th>
                  </tr>
                </thead>
                <tbody>
                  {yourHistoryData.map((history, index) => {
                    const totalBetAmount = history.bets.reduce((sum, bet) => sum + bet.amount, 0);

                    return (
                      <React.Fragment key={index}>
                        <tr
                          className="border-b border-gray-700 cursor-pointer"
                          onClick={() => toggleRow(index)}
                        >
                          <td className="py-2 text-left">{history.id}</td>
                          <td className="py-2 text-center">₹{totalBetAmount}</td>
                          <td className="py-2 text-right flex justify-end items-center space-x-2">
                            {history.winItem && (
                              <React.Fragment>
                                <img src={history.winItem.image} alt={history.winItem.label} className="w-6 h-6 rounded-full" />
                                <span>{history.winItem.label}</span>
                              </React.Fragment>
                            )}
                            <span className="flex items-center">
                              <span className={`w-3 h-3 rounded-full ${history.net_overall_final > 0 ? 'bg-green-500' : 'bg-red-500'} mr-2`}></span>
                              <span>{history.net_overall_final > 0 ? `+₹${history.net_overall_final}` : `-₹${Math.abs(history.net_overall_final)}`}</span>
                            </span>
                          </td>
                        </tr>

                        {expandedRow === index && (
                          <tr className="bg-[#1a1a1a]">
                            <td colSpan="3" className="p-4">
                              <div className="flex flex-col items-start space-y-2">
                                <p><strong>Result:</strong><img className='w-8 h-8 rounded-full' src={getWinnerDetails(history.winner_final).image} alt={getWinnerDetails(history.winner_final).name} /></p>
                                <div>
                                  <p><strong>Bet Outcome:</strong></p>
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                  {history.bets.map((bet, betIndex) => {
                                    const betItem = items.find(item => item.id === bet.blocks);
                                    return betItem ? (
                                      <div key={betIndex} className="flex items-center space-x-1 p-1 rounded-lg text-[0.8rem]">
                                        <img src={betItem.image} alt={betItem.label} className="w-8 h-8 rounded-full" />
                                        <div>
                                          <p>{betItem.label}</p>
                                          <p>Bet: ₹{bet.amount}</p>
                                        </div>
                                      </div>
                                    ) : null;
                                  })}
                                </div>
                                <p><strong>Total Bets Placed:</strong> ₹{history.bets.reduce((sum, bet) => sum + bet.amount, 0)}</p>
                                <p><strong>Total Win Amount:</strong> ₹{history.win_amount_final}</p>
                                <p><strong>Total Loss Amount:</strong> ₹{history.lose_amount_final}</p>
                                <p><strong>Net Outcome: </strong> {history.net_overall_final > 0 ? `+₹${history.net_overall_final}` : `-₹${Math.abs(history.net_overall_final)}`}</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            )}
            <div className="flex justify-center items-center mt-4">
              <button
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
                className={`${
                  currentPage === 1 ? 'text-gray-600' : 'text-gray-400'
                } cursor-pointer p-2`}
              >
                <FaArrowLeft />
              </button>
              <span>
                Page {currentPage} of {totalPages ? totalPages : 1}
              </span>
              <button
                className={`${
                    currentPage === totalPages ? 'text-gray-600' : 'text-gray-400'
                } cursor-pointer p-2`}
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <FaArrowRight />
              </button>
            </div>
          </>
        )}
      </div>
      {showBottom && <NewBottomBar />}
    </div>
  );
};

export default PrevHistory;
